.recommended-products {
  margin-bottom: 55px;

  h2 {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.55;
  }

  .recommended-list {
    .product-small-element {
      position: relative;
      margin-top: -1px;
      padding: 6px 24px 6px 0;
      border: 1px solid $color-light-grey;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      &.active {
        z-index: 1;
        border-color: $color-green;
      }

      input {
        display: none;

        &:checked {
          ~ .check-wrp {
            .check {
              background: $color-green;
              border-color: $color-green;

              &:before {
                display: inline;
              }
            }
          }
        }
      }

      .check-wrp {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 0 8px 8px 0;
        line-height: 0;

        .check {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          border: 1px solid $color-light-grey;
          font-size: 8px;
          line-height: 0;
          color: $color-white;

          &:before {
            display: none;
          }
        }
      }

      .img-wrp {
        img {
          width: 88px;
          height: 88px;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 0;

        .brand {
          margin-bottom: 5px;
        }

        .name {
          display: block;
          margin-top: 0;
          margin-bottom: 8px;
          line-height: 1.33;
        }

        .price {
          &.discount {
            flex-direction: row;
            align-items: center;
          }

          .old-price {
            margin-left: 8px;
          }
        }
      }
    }

    .recommended-summary {
      margin-top: -1px;

      .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 24px;
        background: $color-light-grey4;
        border: 1px solid $color-light-grey;
      }

      .button {
        width: 100%;
      }

      .title {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
      }

      .price {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.3;
        text-align: right;

        &.discount {
          color: $color-red;

          .old-price {
            display: block;
          }
        }

        .old-price {
          display: none;
          font-weight: 300;
          font-size: 14px;
          text-decoration-line: line-through;
          color: $color-dark-grey;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .recommended-list {
      display: flex;
      justify-content: flex-start;
      padding: 25px 35px;
      border: 1px solid $color-light-grey;

      .product-small-element {
        flex-direction: column;
        flex-grow: 1;
        width: calc(100% - 280px);
        margin-right: 20px;
        margin-top: 0;
        padding: 0;
        border: 0;

        &:hover {
          .img-wrp {
            img {
              transform: scale(1.2);
            }
          }
        }

        .img-wrp {
          margin-bottom: 16px;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
            aspect-ratio: 1/1;
            will-change: transform;
            transform-origin: center center;
            transition: all 0.4s ease-in-out;
          }
        }

        .details {
          display: flex;
          flex-direction: column;
          margin-left: 0;

          .name {
            margin-top: 8px;
            font-size: 14px;
            line-height: 1.5;

            a {
              &:hover {
                text-decoration-line: underline;
              }
            }
          }

          .price {
            font-size: 14px;
            margin-top: auto;

            .old-price {
              font-size: 12px;
            }
          }
        }
      }

      .recommended-summary {
        width: 200px;
        flex-shrink: 0;
        margin-top: 0;
        padding: 32px 21px;
        border: 1px solid $color-light-grey;
        background: $color-light-grey4;

        .inner {
          display: flex;
          flex-direction: column;
          border: 0;
          padding-left: 20px;
          padding-right: 20px;
        }

        .title {
          margin-bottom: 24px;
          text-align: center;
        }

        .price {
          margin-left: 0;
          font-size: 24px;
          text-align: center;

          &.discount {
            .old-price {
              opacity: 1;
            }
          }

          .old-price {
            display: block;
            opacity: 0;
          }
        }

        .button {
          padding: 16px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 90px;

    h2 {
      margin-bottom: 42px;
      font-size: 28px;
      line-height: 1.428;
    }

    .recommended-list {
      padding: 45px 56px;

      .product-small-element {
        width: calc(100% - 342px);
        margin-right: 32px;

        .details {
          .name {
            font-size: 16px;
          }

          .price {
            font-size: 16px;

            .old-price {
              margin-left: 12px;
            }
          }
        }
      }

      .recommended-summary {
        width: 214px;
        margin-left: auto;

        .inner {
          padding-bottom: 48px;
        }

        .title {
          margin-bottom: 34px;
        }

        .price {
          font-size: 28px;
          font-weight: 500;
        }
      }
    }
  }
}