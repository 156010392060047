#mega-menu-desktop {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-fixed;
  display: flex;
  width: 1036px;
  border: 1px solid $color-light-grey;
  background-color: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .main-menu {
    flex-shrink: 0;
    width: 223px;
    max-height: calc(100vh - 180px);
    overflow: auto;
    padding: 16px 8px 24px;
    border-right: 1px solid $color-light-grey;

    li {
      a {
        display: block;
        padding: 8px 16px;
        color: $color-dark;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        transition: all 0.3s ease;

        &:hover, &.active {
          color: $color-green;
          background-color: $color-light-grey4;
        }
      }
    }
  }

  .main-menu-submenu {
    flex-grow: 1;
    padding: 30px 40px 40px;
    max-height: calc(100vh - 180px);
    overflow: auto;

    .menu_lvl2 {
      .menu-col-group {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      h4 {
        margin-bottom: 10px;

        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          font-style: normal;
          font-size: 14px;
          line-height: 1.33;
          color: $color-dark;

          &:hover {
            text-decoration-line: underline;
          }

          i {
            margin-left: 12px;
            font-size: 10px;
          }
        }
      }
    }

    .menu {
      line-height: 0;

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: inline-block;
          font-weight: 300;
          font-size: 12px;
          line-height: 1.33;
          color: $color-dark;

          &:hover {
            text-decoration-line: underline;
          }
        }
      }
    }

    .additional-menu {
      padding: 8px 16px;
      margin-bottom: 24px !important;
      background: $color-light-grey4;

      > li {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        > a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 400;
          font-size: 14px;
          line-height: 1.33;
          color: $color-dark;
          transition: all 0.3s ease;

          &:hover {
            text-decoration-line: underline;
          }

          &.highlight-green {
            color: $color-green;
          }

          *:first-child {
            width: 20px;
            margin-right: 18px;
            font-size: 20px;
            text-align: center;

            &.icon-sign-out {
              font-size: 15px;
            }
          }
        }
      }
    }

    .submenu-brands-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;

      li {
        a {
          display: flex;
          flex-direction: column;

          &:hover {
            text-decoration-line: none;

            .img-wrp {
              img {
                &.color {
                  display: block;
                }

                &.grey {
                  display: none;
                }
              }
            }
          }

          .img-wrp {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            background: $color-light-grey4;
            aspect-ratio: 2.08/1;
            line-height: 0;

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              padding: 20px;
              object-fit: contain;

              &.color {
                display: none;
              }
            }
          }

          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.33;
            text-align: center;
            color: $color-dark;
          }
        }
      }
    }

    .banner-img {
      text-align: right;
    }

    .category-brands {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      margin-bottom: 24px !important;

      li {
        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          aspect-ratio: 1.73/1;
          background: $color-light-grey4;

          &:hover {
            img {
              &.color {
                display: block;
              }

              &.grey {
                display: none;
              }
            }
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 10px 12px;
            object-fit: contain;

            &.color {
              display: none;
            }
          }
        }
      }
    }
  }
}