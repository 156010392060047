body {
  padding-top: 72px !important;
  background: $color-white;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.875;
  color: $color-dark;

  &.no-header {
    padding-top: 0 !important;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 0 !important;
  }
}

* {
  outline: none;
}

a {
  text-decoration: none;
}

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
}

.container, .container-fluid {
  --bs-gutter-x: 60px;

  @include media-breakpoint-up(xl) {
    --bs-gutter-x: 30px;
  }
}

.row {
  --bs-gutter-x: 60px;

  > * {
    --bs-gutter-x: 60px;
  }

  @include media-breakpoint-up(xl) {
    --bs-gutter-x: 30px;

    > * {
      --bs-gutter-x: 30px;
    }
  }
}

.page-wrapper {
  overflow: hidden;
}

.btn-group {
  border-radius: 0;
}

.header-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-dropdown;
  background: rgba($color-dark, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.collapse-header {
  position: relative;

  &[aria-expanded="true"] {
    i {
      transform: rotate(180deg);
    }
  }

  i {
    transform-origin: center;
    transition: all 0.3s ease;
  }
}

.form-control {
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 0;
  border: 1px solid $color-light-grey;
  font-weight: 300;
  font-size: 14px;
  color: $color-dark;

  &::placeholder {
    color: $color-dark-grey;
  }

  &:focus {
    border-color: $color-dark;
    box-shadow: none;
  }

  &:disabled, &.disabled {
    border-color: $color-light-grey;
    background: $color-light-grey4;
    color: $color-dark-grey;
    cursor: not-allowed;

    &.pointer {
      cursor: pointer;
    }
  }
}

.checkbox-group {
  margin-top: 32px;

  .checkbox {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .dont-collapse-md {
    display: block !important;
    height: auto !important;
    visibility: visible !important;
  }
}

@keyframes c {
  0% {
    transform: translate(-50%, -50%) rotate(0)
  }
  50% {
    transform: translate(-50%, -50%) rotate(-180deg)
  }
  to {
    transform: translate(-50%, -50%) rotate(-1turn)
  }
}

#ajax_loader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba($color-dark, 0.6);

  .load-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    animation: c 1.8s linear infinite;

    &:before, &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-radius: 20px;
      background: hsla(0, 0%, 100%, 0.9);
    }

    &:before {
      left: 0;
      animation: d 1.8s linear infinite;
    }

    &:after {
      right: 0;
      animation: e 1.8s linear infinite;
      animation-delay: -0.9s;
    }
  }
}

.editor-html-content {
  .page_editor_template {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    .page_editor_template {
      margin-bottom: 64px;
    }
  }
}

.block-spacing {
  margin-bottom: 72px;

  &.no_margin_bottom {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 96px;
  }
}

.html-content {
  *:last-child {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }

  iframe {
    max-width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    color: $color-green;
    @include link-style();
  }

  ol, ul {
    margin-bottom: 20px;

    li {
      position: relative;

      &:before {
        position: absolute;
      }
    }
  }

  ol {
    counter-reset: item;

    li {
      padding-left: 33px;
      margin-bottom: 10px;
      list-style-type: none;
      counter-increment: item;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        top: 0;
        left: 0;
        display: inline-block;
        font-weight: 500;
        content: counter(item) ".";
      }
    }
  }

  ul {
    li {
      padding-left: 24px;

      &:before {
        top: 14px;
        left: 10px;
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: $color-dark;
        content: '';
      }
    }
  }

  @include media-breakpoint-up(xs) {
    p {
      margin-bottom: 30px;
    }

    ol, ul {
      margin-bottom: 30px;
    }

    ol {
      li {
        margin-bottom: 16px;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}