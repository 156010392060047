@use "sass:map";

#products-detailed {
  margin-bottom: 40px;

  .back-wrp {
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: -30px;
    margin-right: -30px;
    background: $color-light-grey4;
    font-size: 12px;
    line-height: 1.5;

    a {
      color: $color-dark;

      i {
        margin-right: 10px;
        font-size: 8px;
      }
    }
  }

  .product-details {
    margin-bottom: 55px;
  }

  .read-more {
    display: inline-block;
    padding: 0;
    margin-top: 20px;
    border: 0;
    background: none;
    font-size: 14px;
    line-height: 1;
    color: $color-green;
  }

  .product-summary {
    position: relative;
    margin-bottom: 48px;
  }

  .add-to-cart {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 20px;
  }

  .chargers-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    border: 1px solid $color-green;
    background: none;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.33;

    .fa-car-battery {
      margin-right: 10px;
      font-size: 18px;
    }

    .icon-angle-right {
      margin-left: 10px;
      font-size: 12px;
    }

    span {
      margin-left: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      color: $color-green;
      transition: all 0.3s ease;
    }
  }

  .info-block {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 24px;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.55;
    }

    .description {
      max-height: 360px;
      overflow: hidden;
    }

    .html-content {
      font-size: 14px;
      line-height: 1.428;

      &.info-content {
        table {
          width: 100% !important;
          border: 0;

          tr {
            display: block;

            td {
              display: block;
              width: auto !important;
              margin-bottom: 28px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      p {
        margin-bottom: 20px;
      }

      ul {
        li {
          margin-bottom: 20px;

          &:before {
            top: 7px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .specs {
    .table-wrp {
      overflow: auto;

      table {
        width: 100%;
        table-layout: fixed;

        &.limited-height {
          &.show {
            tr {
              &:nth-child(n+6) {
                display: table-row;
              }
            }
          }

          tr {
            &:nth-child(n+6) {
              display: none;
            }
          }
        }

        tr {
          border-bottom: 1px solid rgba($color-dark-grey, 0.4);

          &:first-child {
            td {
              padding-top: 0;
            }
          }

          td {
            padding: 40px 0 20px;
            font-size: 14px;
            line-height: 1.428;
            vertical-align: top;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .back-wrp {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .back-wrp {
      a {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }

    .read-more {
      &:hover {
        text-decoration-line: underline;
      }
    }

    .chargers-btn {
      &:hover {
        span {
          text-decoration-line: underline;
        }
      }
    }

    .product-summary {
      display: grid;
      grid-template-columns: 49% 40%;
      column-gap: 11%;
      align-items: flex-start;
      margin-top: 25px;
      margin-bottom: 60px;
    }

    .product-details {
      display: grid;
      grid-template-columns: 49% 40%;
      grid-template-areas:
        "description chargers-btn"
        "description specs"
        "contact-desc specs";
      grid-template-rows: auto auto 1fr;
      gap: 48px 11%;

      &.chargers-modal-none {
        grid-template-areas:
        "description specs"
        "description specs"
        "contact-desc specs";
      }
    }

    .product-summary{
      .product-actions {
        top: -6px;
        right: -6px;
      }
    }

    .chargers-btn {
      grid-area: chargers-btn;
    }

    .info-block {
      margin-bottom: 0;

      &.description {
        grid-area: description;
      }

      &.specs {
        grid-area: specs;
      }

      &.contact-desc {
        grid-area: contact-desc;
      }

      .description {
        max-height: 250px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 28px;
    margin-bottom: 88px;

    .product-summary{
      .product-actions {
        top: -8px;
        right: -8px;
      }
    }

    .chargers-btn {
      justify-content: flex-start;
      padding-left: 24px;
      padding-right: 24px;

      .fa-car-battery {
        margin-right: 20px;
      }

      span {
        margin-left: auto;
      }
    }

    .back-wrp {
      background: none;

      .container-fluid {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .product-summary {
      margin-top: 17px;
    }

    .read-more {
      margin-top: 15px;
    }

    .info-block {
      h2 {
        font-size: 28px;
        line-height: 1.428;
      }

      .description {
        max-height: 230px;
      }

      .html-content {
        &.info-content {
          table {
            table-layout: fixed;

            tr {
              display: table-row;

              td {
                display: table-cell;
                margin-bottom: 0;
                vertical-align: top;

                &:nth-child(1) {
                  padding-right: 20px;
                }

                &:nth-child(2) {
                  padding-left: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}