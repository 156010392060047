#customers {
  margin-top: 25px;

  @include media-breakpoint-up(lg) {
    margin-top: 30px;
    margin-bottom: 48px;

    .customers-container {
      display: flex;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-top: 70px;
    margin-bottom: 88px;

    .customers-container {
      display: flex;
      justify-content: flex-start;
    }
  }
}