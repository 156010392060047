.profile-dropdown {
  width: 216px;
  padding: 34px 38px 24px;
  border-radius: 0;
  border: 1px solid $color-light-grey;
  background: $color-white;

  li {
    margin-left: 0 !important;
    margin-bottom: 22px;

    &:last-child {
      margin-bottom: 0;
    }

    &.drop-title {
      margin-bottom: 34px;
      font-weight: 500;
      font-size: 16px;
    }

    a {
      padding: 0 !important;
      font-size: 14px !important;
      line-height: 1.25;
      font-weight: 300;
      color: $color-dark;
    }
  }
}