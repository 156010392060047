#products-add2cart {
  .brand {
    display: inline-block;
    font-size: 14px;
    line-height: 1.14;
    color: $color-dark;
  }

  .name {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.55;
  }

  .text {
    font-size: 14px;
    line-height: 1.42;

    strong {
      font-weight: 500;
    }
  }

  .notice {
    margin-bottom: 12px;
  }

  .code {
    margin-bottom: 6px;
  }

  .warranty {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42;
    color: $color-green;
  }

  .item-selection {
    margin-top: 45px;
    margin-bottom: 25px;

    .item {
      display: flex;
      align-items: center;
      width: 100%;
      background: none;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.428;
      color: $color-dark;
      text-align: left;
      cursor: pointer;

      picture {
        margin-right: 20px;
        flex-shrink: 0;
      }

      img {
        width: 56px;
        height: 56px;
        object-fit: contain;
      }
    }

    button {
      padding: 3px 40px 3px 4px;
      border: 1px solid $color-light-grey;

      &.show {
        border-color: $color-dark;
      }

      i {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        font-size: 8px;
      }
    }

    .dropdown-menu {
      width: 100%;
      padding: 12px 0 16px;
      border-radius: 0;
      border-color: $color-dark;

      li {
        padding: 12px 16px 12px 4px;

        &.selected {
          font-weight: 600;
        }
      }
    }
  }

  .price-availability {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;

    .price {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 18px;
      line-height: 1;

      &.discount {
        color: $color-red;
      }

      .old-price {
        margin-left: 12px;
        font-weight: 300;
        font-size: 14px;
        text-decoration-line: line-through;
        color: $color-dark-grey;
      }
    }

    .availability {
      position: relative;
      display: inline-block;
      padding-left: 20px;
      font-size: 12px;
      line-height: 1;

      &.out-of-stock {
        &:before {
          background: $color-red;
        }
      }

      &:before {
        position: absolute;
        top: 2px;
        left: 0;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background: $color-light-green2;
        content: '';
      }
    }
  }

  .coupon-block {
    h4 {
      margin-top: 25px;
      margin-bottom: 5px;
      font-size: 16px;
      line-height: 1.25;
    }

    .form-item {
      margin-bottom: 16px;
    }

    .checkbox {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  @include media-breakpoint-up(md) {
    .name {
      font-size: 28px;
      line-height: 1.4285;
    }

    .price-availability {
      .price {
        font-size: 28px;

        .old-price {
          margin-left: 15px;
          font-size: 16px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .warranty {
      &:hover {
        text-decoration-line: underline;
      }
    }

    .brand {
      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}