#pagination-ajax {
  margin-top: 40px;

  .button {
    width: 100%;
  }

  .page-info {
    display: block;
    margin-top: 16px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-dark-grey;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    margin-top: 60px;
    text-align: center;

    .button {
      width: auto;
      min-width: 300px;
    }
  }
}