#comparison-info {
  .comparison-inner-wrp {
    position: relative;
    display: inline-block;
    line-height: 0;

    .cnt {
      position: absolute;
      top: -9px;
      right: -8px;
      display: block;
      width: 17px;
      height: 17px;
      border-radius: 100%;
      background: $color-green;
      border: 2px solid $color-white;
      font-weight: 500;
      font-size: 8px;
      line-height: 13px;
      text-align: center;
      color: $color-white;
    }
  }
}