#orders-pay {
  #payment-type-options {
    margin-bottom: 30px;

    .form-item {
      margin-bottom: 16px;
    }

    .payment-body {
      padding-top: 16px;
    }
  }

  .payment-desc {
    grid-column: span 2;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.33;

    *:last-child {
      margin-bottom: 0;
    }

    .payment-logo {
      margin-bottom: 15px;

      img {
        max-height: 50px;
        max-width: 140px;
      }
    }
  }

  .payment-bank-options {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      padding: 16px 18px;
      border: 1px solid $color-light-grey;
      cursor: pointer;

      input {
        &:checked {
          ~ .check-border {
            border-color: $color-green;
          }
        }
      }

      .check-border {
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: 1;
        display: block;
        border: 1px solid transparent;
        pointer-events: none;
      }

      .check {
        position: absolute;
        top: -1px;
        left: -1px;
        border-color: $color-light-grey;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .payment-bank-options {
      gap: 25px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}