#news-detailed-page {
  .back-top-box {
    padding-top: 5px;
    padding-bottom: 5px;
    background: $color-light-grey4;
  }

  .back-link {
    font-size: 12px;
    line-height: 1;
    color: $color-dark;
    transition: all 0.3s ease;

    i {
      margin-right: 10px;
    }
  }

  .main-image-box {
    margin-bottom: 38px;
  }

  .main-image {
    height: 285px;
    width: 100%;
    object-fit: cover;
  }

  time {
    font-size: 16px;
    line-height: 1;
    color: $color-dark-grey;
  }

  .page-title {
    margin-top: 12px;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.25;
  }

  @include media-breakpoint-up(xs) {
    .page-title {
      font-size: 32px;
    }
  }
  @include media-breakpoint-up(md) {
    padding-top: 56px;

    .main-image-box {
      margin-bottom: 48px;
    }

    .top-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    time {
      font-size: 12px;
    }

    .page-title {
      margin-top: 20px;
      font-size: 28px;
      line-height: 1.428;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 88px;

    .back-link {
      &:hover {
        color: $color-light-green;
      }
    }
    .container-header {
      max-width: 830px;
    }

    .editor_type_text {
      .container {
        max-width: 830px;
      }
    }

    time {
      color: $color-dark;
    }
  }
}