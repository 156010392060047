#linepromos {
  position: relative;

  .tns-controls {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    pointer-events: none;

    button {
      width: 25px;
      padding: 5px;
      border: 0;
      background: none;
      color: $color-white;
      font-size: 16px;
      pointer-events: all;
    }
  }

  .linepromos-carousel {
    display: flex;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.tns-slider {
      opacity: 1;
    }

    .item {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 14px;
      line-height: 1.428;
      text-align: center;
    }
  }

  @include media-breakpoint-up(lg) {
    .tns-controls {
      padding-left: 15px;
      padding-right: 15px;
    }

    .linepromos-carousel {
      .item {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    position: sticky;
    top: 100px;
    z-index: 900;
  }
}