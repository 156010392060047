.product-photos-wrp {
  position: relative;
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 25px;

  .product-photos {
    a {
      display: block;
      line-height: 0;
      text-align: center;

      img {
        aspect-ratio: 1/1;
        object-fit: contain;
      }
    }
  }

  .photos-counter {
    position: absolute;
    bottom: 0;
    left: 30px;
    z-index: 1;
    display: block;
    padding: 10px 9px;
    background: $color-dark;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    color: $color-white;
  }

  .tags {
    top: 17px;
    left: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 140px);

    .tag {
      margin-bottom: 8px;
      margin-right: 8px;
      font-weight: 400;
    }
  }

  .tns-controls {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    transform: translateY(-50%);
    pointer-events: none;

    button {
      padding: 10px;
      border: 0;
      background: none;
      line-height: 0;
      font-size: 14px;
      pointer-events: all;
    }
  }

  .product-photos-nav {
    width: 56px;
    padding-right: 16px;
    flex-shrink: 0;

    button {
      position: relative;
      margin-bottom: 8px;
      padding: 0;
      border: 0;
      background: none;

      &:last-child {
        margin-bottom: 0;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        content: '';
      }

      &:hover {
        &:after {
          border: 1px solid $color-light-grey;
        }
      }

      &.tns-nav-active {
        &:after {
          border: 1px solid $color-dark;
        }
      }

      img {
        aspect-ratio: 1/1;
      }
    }
  }

  .product-photos-inner-wrp {
    position: relative;
  }

  .photos-info {
    margin-top: 8px;
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 300;
    font-size: 10px;
    line-height: 1.6;
    color: $color-dark-grey;
  }

  @include media-breakpoint-up(md) {
    margin-left: 0;
    margin-right: 0;

    .photos-info {
      padding-left: 0;
      padding-right: 0;
      font-size: 12px;
      line-height: 1.33;
    }

    .photos-counter {
      left: 0;
    }

    .photos-info {
      text-align: center;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;

    .tags {
      top: 0;
      left: 56px;
      flex-direction: column;

      .tag {
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .tags {
      .tag {
        padding: 11px 14px;
        font-size: 16px;
      }
    }
  }
}