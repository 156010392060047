.tags {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .tag {
    display: inline-block;
    padding: 6px 8px;
    margin-bottom: 4px;
    background: $color-blue;
    font-weight: 500;
    font-size: 10px;
    line-height: 1.33;
    color: $color-white;

    &:last-child {
      margin-bottom: 0;
    }

    &.tag-discount {
      background: $color-red;
    }
  }

  @include media-breakpoint-up(xl) {
    .tag {
      padding: 8px 12px;
      font-size: 12px;
    }
  }
}