.collection {
  .collection-header {
    margin-bottom: 35px;

    h2 {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 0;

      a {
        color: $color-dark;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .collection-controls {
    display: none;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 48px;
      border: 1px solid $color-dark;
      background: rgba($color-dark, 0);
      font-size: 16px;
      line-height: 1;
      color: $color-dark;

      + button {
        margin-left: -1px;
      }
    }
  }

  .collection-items {
    display: flex;
    margin-right: -30px;
    overflow: auto;

    .citem-wrp {
      display: flex;
    }

    .product-element {
      flex-shrink: 0;
      width: 204px;

      &.hover {
        z-index: 2;

        .product-element-inner {
          position: static;
          box-shadow: none;
          border: 1px solid $color-dark-grey;
        }

        .add-to-cart-listing {
          display: inline-block;
        }
      }

      .product-element-inner {
        border: 1px solid $color-light-grey5;
        transition: all 0.3s ease;
      }

      .img-wrp {
        margin-bottom: 18px;
      }

      .brand {
        margin-bottom: 10px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .collection-header {
      margin-bottom: 50px;
    }

    .collection-items {
      margin-right: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .collection-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 28px;
      }
    }

    .collection-controls {
      display: flex;

      button {
        transition: all 0.3s ease;

        &:hover:not([disabled]) {
          background: $color-dark;
          color: $color-white
        }

        &[disabled] {
          border-color: $color-dark-grey;
          color: $color-dark-grey
        }
      }
    }

    .collection-items {
      overflow: visible;

      &.hover {
        .product-element-inner {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          background: $color-white;
          box-shadow: 0 4px 17px rgba(0, 0, 0, 0.13);
          z-index: 1;
        }
      }

      .product-element {
        width: 100%;

        .add-to-cart-listing {
          display: inline-block;
        }
      }
    }
  }
}