#cart-summary {
  .col {
    &:last-child {
      padding-left: 0;
    }
  }

  .summary {
    font-size: 14px;
    line-height: 1.5;

    .row {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .discount {
      color: $color-red;
    }
  }

  .total {
    margin-top: 17px;
    font-weight: 500;
    line-height: 1.5;
  }
}