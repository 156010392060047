@use "sass:map";

#departments_listing {

  .map-main-wrp {
    position: relative;

    #mapBlock {
      #shopsMap {
        height: 450px;
        width: 100%;
        overflow: hidden;
      }
    }

    .map-search-wrp {
      position: absolute;
      bottom: 16px;
      width: 100%;

      .search-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: .3rem;
        background-color: #ffffff;
        box-shadow: 1px 1px 5px #9a9a9a;
        border: 1px solid #e7e7e7;

        .title-wrp {
          margin-right: 15px;
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          text-transform: uppercase;
        }

        .input-wrp {
          flex-grow: 1;

          #pac-input {
            height: 34px;
          }
        }
      }
    }
  }

  .filters-wrp {
    flex-grow: 0;
    flex-shrink: 0;
    display: block;
    width: 30%;

    h3 {
      margin-bottom: 20px;
    }

    .filters-group-wrp {
      h4 {
        margin-bottom: 15px;
        font-size: 14px;
      }

      ul {
        margin-bottom: 0px;
        padding-bottom: 20px;
        list-style: none;

        li {
          margin-bottom: 5px;

          .custom-checkbox {
            label {
              display: flex;
              flex-direction: row;
              align-items: center;

              img {
                align-self: flex-start;
                margin-right: 10px;
                width: 20px;
              }

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .layout-wrp {
    display: flex;
    flex-direction: row;

    .departments-wrp {
      flex-grow: 1;

      > .subtitle {
        font-size: 30px;
      }

      .city-wrp {
        margin-bottom: 20px;
        padding: 20px;
        background-color: map.get($theme-colors, 'primary');

        label {
          font-size: 18px;
          color: #ffffff;
        }

        .bootstrap-select {
          width: 100%;
        }
      }

      .elements_listing {
        flex-direction: column;
        flex-wrap: nowrap;

        .element {
          .head {
            margin-bottom: 30px;
            border-top: 1px solid #B1B1B1;
            border-bottom: 1px solid #B1B1B1;
          }

          .content-wrp {
            .distance {
              .icon-distance {
                display: inline-block;
                margin-right: 5px;
                height: 8px;
                width: 16px;
                background: url("/images/frontend/icon-distance.png") no-repeat center/cover;
              }
            }

            address {
              font-size: 16px;
              line-height: 22px;
            }

            .working-hours {
              h3 {
                font-size: 14px;
              }

              p {
                font-size: 16px;
                line-height: 22px;
              }
            }

            .filters {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
              margin: 0px -5px;
              list-style: none;

              li {
                margin: 5px;

                img {
                  width: 30px;
                }
              }
            }
          }
        }

        .empty-list {
          margin-top: 40px;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .map-main-wrp {
      .map-search-wrp {
        position: static;

        .search-container {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 10px;
          box-shadow: none;
          border: none;

          .input-wrp {
            width: 100%;
          }
        }
      }
    }

    .filters-wrp { // perkeliamas su js, pagal rezoliucija, kad nedubliuot kodo
      margin-bottom: 20px;
      width: 100%;

      h3 {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: 10px 40px 10px 10px;
        color: #ffffff;
        background-color: map.get($theme-colors, 'primary');

        &:after {
          content: "";
          position: absolute;
          top: 15px;
          right: 15px;
          display: block;
          width: 10px;
          height: 10px;
          border-right: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
          transform: rotate(45deg);
          transition: all .3s;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .filter-groups-wrp {
        max-height: 0px;
        transition: all .3s;
        overflow: hidden;

        .inner-wrp {

        }
      }

      &.open {
        h3 {
          &:after {
            top: 20px;
            transform: rotate(-135deg);
          }
        }
      }
    }
    .layout-wrp {
      .departments-wrp {
      }
    }
  }
  @include media-breakpoint-up(md) {
    .filters-wrp {
      padding-right: 20px;

      & + .departments-wrp {
        padding-left: 20px;
      }
    }
    .layout-wrp {
    }
  }
}