.tabs-nav {
  li {
    display: block;
    width: 100%;

    + li {
      margin-top: -1px;
    }

    button {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 23px 16px;
      background: $color-light-grey4;
      border: 1px solid $color-light-grey;
      text-align: left;
      font-weight: 300;
      font-size: 14px;
      line-height: 1;
      color: $color-dark;

      &.active {
        z-index: 2;
        background: $color-white;
        border-color: $color-green;

        .radio {
          border-width: 4px;
          border-color: $color-green;
        }
      }

      .radio {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: $color-white;
        border: 1px solid $color-light-grey;
      }

      .title {
        margin-left: 13px;
        margin-right: 16px;
      }

      .price {
        margin-left: auto;
        text-align: right;
        font-weight: 500;
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;

    li {
      flex-grow: 1;
      flex-basis: 0;
      width: auto;

      button {
        .radio {
          display: none;
        }

        .title {
          margin-left: 0;
        }
      }
    }
  }
}