#products-listing-filter-selected {
  display: flex;
  margin-top: 24px;
  margin-right: -30px;
  overflow: auto;

  .filter-item-selected {
    display: flex;
    align-items: center;
    padding: 12px 7px 12px 12px;
    margin-right: 8px;
    border: 1px solid rgba($color-dark-grey, 0.4);
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;

    button {
      margin-left: 7px;
      padding: 0 5px;
      border: 0;
      background: none;
      line-height: 0;

      i {
        font-size: 8px;
      }
    }
  }

  .filter-clear-all {
    padding: 0;
    margin-left: 24px;
    margin-right: 8px;
    border: 0;
    background: none;
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    white-space: nowrap;
  }
}