.page-sidebar-img-left {
  .page-content {
    padding-top: 40px;
  }

  .side-img {
    margin-left: -30px;
    margin-right: -30px;

    img {
      width: 100%;
      height: 285px;
      object-fit: cover;
    }
  }

  .page-title {
    margin-bottom: 28px;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.25;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 40px;

    .side-img {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .page-title {
      margin-bottom: 32px;
      font-size: 28px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-top: 72px;
    margin-bottom: 88px;

    .container {
      display: flex;
      align-items: flex-start;
    }

    .side-img {
      width: 30%;

      img {
        width: 100%;
        height: 670px;
      }
    }

    .page-content {
      width: 70%;
      padding-top: 0;
      padding-left: 60px;
    }
  }
  @include media-breakpoint-up(xxl) {
    .side-img {
      width: 36%;
    }

    .page-content {
      width: 64%;
      padding-left: 80px;
    }
  }
}