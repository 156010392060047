#products-listing-sorting {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  padding-top: 23px;
  padding-bottom: 20px;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .close-btn {
    top: 26px;
    z-index: 10;
  }

  .title {
    display: block;
    margin-bottom: 28px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.55;
  }

  ul {
    li {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 14px;
        line-height: 1.428;
        color: $color-dark;

        &.active {
          font-weight: 500;
        }
      }
    }
  }
}