#orders-detailed {
  .back-wrp {
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: -30px;
    margin-right: -30px;
    font-size: 12px;
    line-height: 1.5;

    a {
      text-decoration-line: none;
      color: $color-dark;

      &:hover {
        text-decoration-line: underline;
      }

      i {
        margin-right: 10px;
        font-size: 8px;
      }
    }
  }

  .h-title {
    font-size: 28px;

    .status {
      margin-left: 24px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.25;
    }
  }

  .date {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.25;
    color: $color-dark-grey;

    .status {
      display: inline-block;
      margin-left: 24px;
    }
  }

  h3 {
    margin-top: 32px;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.25;

    &.mt0 {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 16px;

    a {
      color: $color-green;
      @include link-style();
    }
  }

  .order-details {
    margin-bottom: 58px;
  }

  .notice {
    margin-top: 58px;
  }

  .order-actions {
    display: grid;
    row-gap: 16px;
    margin-top: 24px;

    .button {
      position: relative;
      width: 100%;

      i {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        font-size: 18px;
      }
    }
  }

  #cart-items {
    margin-top: 28px;
    margin-bottom: 38px;

    .product-small-element {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba($color-dark-grey, 0.4);

      &:first-child {
        padding-top: 24px;
        border-top: 1px solid rgba($color-dark-grey, 0.4);
      }
    }
  }

  #orders-pay {
    margin-bottom: 58px;
  }

  @include media-breakpoint-up(md) {
    .order-details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }

    .details {
      order: 1;
    }

    .notice {
      order: 2;
      margin-top: 0;

      h3 {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }

    .order-actions {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      order: 3;
      grid-column: span 2;
      margin-top: 0;
    }

    #cart-summary {
      max-width: 310px;
      margin-left: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    .back-wrp {
      padding-top: 0;
      padding-bottom: 0;
      margin-left: calc(var(--bs-gutter-x) * -0.5);
      margin-right: calc(var(--bs-gutter-x) * -0.5);
    }

    .h-title {
      margin-bottom: 15px;
    }

    #cart-items {
      .product-small-element {
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &:first-child {
          padding-top: 20px;
        }

        .inner-wrp {
          margin-bottom: 0;
        }
      }
    }
  }
}