#editable_popup {
  .body-type-text {
    .text-image {
      margin-bottom: 20px;
    }
  }

  .body-type-pren {
    .html-content {
      margin-bottom: 30px;
    }

    #subscribers_subscribe {
      .title {
        font-size: 20px;
        margin-bottom: 20px;
      }

      .html-content {
        margin-bottom: 20px;
      }

      .form-item {
        margin-bottom: 10px;
      }

      .btn-wrp {
        margin-top: 32px;

        .button {
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .body-type-pren {
      #subscribers_subscribe {
        .btn-wrp {
          text-align: center;

          .button {
            width: auto;
          }
        }
      }
    }
  }
}