$font-family-base: 'Lexend', sans-serif;

$color-dark: #222E25;
$color-white: #FFFFFF;
$color-green: #009C44;
$color-light-green: #33B069;
$color-light-green2: #00833E;
$color-light-grey: #E1E1E1;
$color-light-grey2: #F4F6F4;
$color-light-grey3: #A5ACA2;
$color-light-grey4: #F7FAF7;
$color-light-grey5: #EEEEEE;
$color-dark-grey: #8D918D;
$color-red: #EB5757;
$color-blue: #2D8DB7;

$theme-colors: (
        "primary": #F37A1F,
        "danger": #fa0f3d,
        "success": #5cb85c,
        "info": #5bc0de,
        "warning": #f0ad4e,
        "black-light": #08121F,
        "black": #000000,
        "grey-light": #B1B1B1,
        "grey-darker": #8D918D,
        "white": #FFFFFF,
        "white-darker": #EAEAEA,
);

$grid-gutter-width: 30px;

$spacer: 60px;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
);

$grid-breakpoints: (
        xxs: 0,
        xs: 360px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1810px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1110px,
        xxl: 1200px,
        xxxl: 1290px
);

// scss-docs-start tooltip-variables
$tooltip-font-size: 12px;
$tooltip-max-width: 220px;
$tooltip-color: $color-white;
$tooltip-bg: $color-dark;
$tooltip-border-radius: 0;
$tooltip-opacity: 1;