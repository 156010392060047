.mega-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  .logout {
    margin-top: auto;
  }

  .header-top-menu {
    li {
      a {
        i {
          font-size: 12px !important;
          margin-right: 0 !important;
        }
      }
    }
  }

  .close-btn {
    top: 26px;
    z-index: 10;
  }

  .menu-block {
    border-top: 1px solid $color-light-grey;

    &.profile-menu {
      border-top: 0;
    }
  }

  .menu-header {
    position: relative;
    padding-top: 23px;
    padding-bottom: 23px;
    border-top: 0;
    border-bottom: 1px solid $color-light-grey;

    &.text-center {
      padding-left: 28px;
      padding-right: 28px;
    }

    .menu-title {
      display: inline-block;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.55;
    }

    .menu-level-back {
      position: absolute;
      top: 24px;
      left: -9px;
      z-index: 1;
      padding: 2px 9px;
      font-size: 13px;
      color: $color-dark;
    }
  }

  .all-link {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-light-grey;

    a {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.33;
      color: $color-dark;
    }
  }

  .menu_lvl2 {
    padding-top: 32px;
    padding-bottom: 32px;

    .menu-col {
      &:last-child {
        .menu-col-group {
          &:last-child {
            h4 {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .dropdown {
      a {
        i {
          display: inline-block;
        }
      }
    }

    h4 {
      margin-bottom: 28px;

      a {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 18px;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.33;
        color: $color-dark;

        &.highlight {
          font-weight: 500 !important;
        }

        i {
          position: absolute;
          top: 50%;
          right: 8px;
          z-index: 1;
          display: none;
          transform: translateY(-50%);
          font-size: 13px;
        }
      }
    }
  }

  .menu {
    padding-top: 32px;
    padding-bottom: 32px;

    > li {
      margin-bottom: 28px;

      &.dropdown {
        > a {
          i {
            display: inline-block;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      > a {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 18px;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.33;
        color: $color-dark;

        &.highlight {
          font-weight: 500 !important;
        }

        i {
          position: absolute;
          top: 50%;
          right: 8px;
          z-index: 1;
          display: none;
          transform: translateY(-50%);
          font-size: 13px;
        }
      }
    }
  }

  .submenu {
    position: fixed;
    top: 0;
    left: 24px;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: $color-white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.15s ease-in-out;

    &.active {
      left: 0;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    .container {
      padding-top: 0;
      padding-bottom: 38px;

      > *:last-child:not(.logout) {
        padding-bottom: 0;
      }

      &.profile-container {
        padding-bottom: 0;
      }
    }

    h5 {
      margin-bottom: 40px;

      a {
        position: relative;
        justify-content: flex-start;
        padding-right: 0;
        padding-left: 28px;
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 1;
        color: $color-dark;

        i {
          position: absolute;
          top: 50%;
          left: 0;
          z-index: 1;
          transform: translateY(-50%);
          font-size: 24px;
        }
      }
    }

    h6 {
      display: block;
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.11;
      color: $color-dark;

      a {
        font-weight: 600;
        color: $color-dark;
      }
    }
  }

  .additional-menu {
    padding-top: 30px;
    padding-bottom: 30px;

    > li {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      > a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.142;
        color: $color-dark;
        transition: all 0.3s ease;

        &.highlight-green {
          font-weight: 500;
          color: $color-green;

          &:hover {
            color: $color-light-green;
          }
        }

        *:first-child {
          width: 20px;
          margin-right: 18px;
          font-size: 20px;
          text-align: center;

          &.icon-sign-out {
            font-size: 15px;
          }
        }
      }
    }
  }

  .banner-img {
    padding-top: 34px;
  }

  .category-brands {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px 9px;
    padding-top: 32px;

    li {
      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1.71/1;
        background: $color-light-grey4;

        &:hover {
          img {
            &.color {
              display: block;
            }

            &.grey {
              display: none;
            }
          }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 11px 13px;
          object-fit: contain;

          &.color {
            display: none;
          }
        }
      }
    }
  }

  .submenu-brands-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 16px;
    padding-top: 24px;

    li {
      a {
        display: flex;
        flex-direction: column;

        &:hover {
          text-decoration-line: none;

          .img-wrp {
            img {
              &.color {
                display: block;
              }

              &.grey {
                display: none;
              }
            }
          }
        }

        .img-wrp {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 13px;
          background: $color-light-grey4;
          aspect-ratio: 1.09/1;
          line-height: 0;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            object-fit: contain;

            &.color {
              display: none;
            }
          }
        }

        .name {
          font-weight: 500;
          font-size: 14px;
          line-height: 1.33;
          text-align: center;
          color: $color-dark;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .logout {
      margin-top: 0;
    }

    .category-brands {
      grid-template-columns: repeat(5, 1fr);
    }

    .submenu-brands-list {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @include media-breakpoint-up(lg) {
    .category-brands {
      grid-template-columns: repeat(6, 1fr);
    }

    .submenu-brands-list {
      grid-template-columns: repeat(5, 1fr);

      li {
        a {
          .img-wrp {
            aspect-ratio: 1.5/1;

            img {
              padding: 35px 55px;
            }
          }
        }
      }
    }

    .submenu {
      a {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    position: static;
    height: auto;
    opacity: 1;
    visibility: visible;
    background: $color-light-grey4;

    .menu-block {
      padding-top: 0;
      padding-bottom: 0;
      border-top: 0;
    }

    .container {
      height: auto;
      overflow: visible;
    }

    .submenu-brands-list {
      padding-top: 0;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;

      li {
        a {
          .img-wrp {
            margin-bottom: 20px;
            aspect-ratio: 2.08/1;
          }
        }
      }
    }

    .menu_lvl2 {
      padding-top: 0;

      .menu-col-group {
        margin-bottom: 40px;

        &.no-items {
          margin-bottom: 26px;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      h4 {
        margin-bottom: 18px;

        a {
          padding-right: 0;
          font-size: 14px;
          font-weight: 500;
          transition: all 0.3s ease;

          i {
            position: static;
            display: inline-block;
            margin-left: 12px;
            font-size: 10px;
            transform: none;
          }
        }
      }
    }

    .additional-menu {
      padding: 32px;
      margin-bottom: 24px !important;
      background: $color-light-grey4;

      li {
        margin-bottom: 28px;
      }
    }

    .category-brands {
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      margin-bottom: 24px !important;

      li {
        a {
          aspect-ratio: 1.73/1;

          img {
            padding: 10px 12px;
          }
        }
      }
    }

    .banner-img {
      text-align: right;
    }

    .menu {
      &.main {
        display: flex;
        justify-content: space-between;
        padding-top: 0;
        padding-bottom: 0;

        > li {
          display: inline-block;
          margin-bottom: 0;

          &.active {
            > a {
              color: $color-dark;
              border-bottom: 2px solid $color-dark;
            }

            .submenu {
              opacity: 1;
              visibility: visible;
              pointer-events: all;
            }
          }

          > a {
            display: inline-block;
            padding-top: 20px;
            padding-bottom: 18px;
            border-bottom: 2px solid rgba($color-dark, 0);
            font-weight: 400;
            font-size: 14px;
            text-align: center;
          }
        }
      }

      > li {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        &.dropdown {
          position: static;
        }

        > a {
          padding-right: 0;
          transition: all 0.3s ease;

          i {
            position: static;
            margin-left: 12px;
            font-size: 10px;
            transform: none;
          }
        }
      }
    }

    .submenu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: auto;
      overflow: hidden;

      .container {
        display: block;
        padding-top: 32px;
        padding-bottom: 40px;
      }

      .submenu {
        position: static;
        height: auto;
        margin-top: 17px;
        overflow: visible;

        .container {
          padding: 0;
        }

        .menu {
          padding-top: 0;
          padding-bottom: 0;

          > li {
            margin-bottom: 15px;

            > a {
              font-weight: 300;
              font-size: 12px;
            }
          }
        }
      }

      h6 {
        margin-bottom: 15px;
        font-size: 16px;
      }
    }
  }
}