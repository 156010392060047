#products-listing-filter-desktop {
  align-items: center;
  justify-content: space-between;

  .select-title {
    display: inline-block;
    margin-right: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
  }

  .bootstrap-select {
    + .bootstrap-select {
      margin-left: 55px;
    }

    > .dropdown-toggle {
      background: none;
      outline: none !important;
      border: 0;
      padding-left: 0;
      padding-right: 0;

      &:after {
        margin-left: 6px;
        border-top-color: $color-dark;
      }
    }

    .dropdown-menu {
      max-width: unset;
    }

    .btn {
      font-family: $font-family-base;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.1;
      color: $color-dark;

      &[data-id="sort"] {
        &.dropdown-toggle {
          &:after {
            display: none;
          }

          &:before {
            margin-right: 12px;
            content: "\f0dc";
            font-size: 18px;
            color: $color-dark;
            @include fontawesome();
          }
        }
      }

      &[data-id="page-size"] {
        font-weight: 600;

        &.dropdown-toggle {
          &:after {
            margin-left: 12px;
            content: "\f107";
            font-size: 14px;
            font-weight: 900;
            @include fontawesome();
          }
        }
      }
    }
  }
}