#products-listing-parent {
  margin-bottom: 40px;

  .listing-parent-header {
    .container {
      > .breadcrumb {
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 0;
      }
    }

    .img-wrp {
      margin-left: -30px;
      margin-right: -30px;

      img {
        width: 100%;
        aspect-ratio: 1.3/1;
        object-fit: cover;
      }
    }

    .content {
      margin-top: 40px;

      .breadcrumb {
        display: none;
      }

      h1 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 32px;
        line-height: 1.25;
      }

      .html-content {
        margin-top: 20px;
        font-size: 12px;
        line-height: 1.66;
      }
    }
  }

  .categories-list {
    display: grid;
    row-gap: 40px;

    .item {
      &:hover {
        h3 {
          color: $color-green;
        }
      }

      .img-wrp {
        display: block;
        line-height: 0;
        background-color: $color-light-grey4;

        img {
          width: 100%;
          aspect-ratio: 1.49/1;
          object-fit: cover;
        }
      }

      h3 {
        margin-top: 16px;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.33;
        text-align: center;
        color: $color-dark;
        transition: color 0.3s ease;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .listing-parent-header {
      .img-wrp {
        img {
          aspect-ratio: 2/1;
        }
      }
    }

    .categories-list {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 20px;

      .item {
        .img-wrp {
          img {
            aspect-ratio: 1.48/1;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .categories-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @include media-breakpoint-up(lg) {
    .listing-parent-header {
      .container {
        display: flex;
        align-items: flex-start;

        > .breadcrumb {
          display: none;
        }
      }

      .img-wrp {
        order: 2;
        width: 55%;
        margin-left: 0;
        margin-right: 0;

        img {
          aspect-ratio: 1.85/1;
        }
      }

      .content {
        order: 1;
        width: 45%;
        padding-right: 50px;
        padding-top: 20px;
        margin-top: 0;

        .breadcrumb {
          display: block;
          padding-bottom: 0;
        }
      }
    }

    .categories-list {
      grid-template-columns: repeat(4, 1fr);
      gap: 30px 25px;

      .item {
        &:hover {
          .img-wrp {
            transform: scale(1.1);
          }
        }

        h3 {
          font-weight: 300;
        }

        .img-wrp {
          padding: 25px;
          will-change: transform;
          transform-origin: bottom center;
          transition: all 0.3s ease-in-out;

          img {
            height: auto;
            aspect-ratio: 1.48/1;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .listing-parent-header {
      .img-wrp {
        width: 57%;
      }

      .content {
        width: 43%;
        padding-right: 110px;
        padding-top: 45px;

        h1 {
          font-size: 36px;
          line-height: 1.33;
        }

        .html-content {
          line-height: 2;
        }
      }
    }

    .categories-list {
      gap: 48px 43px;

      .item {
        h3 {
          margin-top: 20px;
        }
      }
    }
  }
}