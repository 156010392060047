.form-item {
  &.has-error {
    input {
      border-color: $color-red;
    }

    .form-message {
      display: block;
    }
  }

  .form-label {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-dark;
  }

  .form-message {
    display: none;
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-red;
  }

  .form-info {
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.33;
    color: $color-dark;

    a {
      color: $color-dark;
      @include link-style();
    }
  }

  textarea {
    height: 130px;
  }
}