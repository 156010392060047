#products-listing-filter-mobile {
  position: relative;
  display: flex;
  margin-left: -30px;
  margin-right: -30px;

  > button {
    width: 50%;

    &.bordered {
      border-left: 0;
      border-right: 0;
    }

    &.selected {
      font-weight: 500;
    }
  }

  @include media-breakpoint-up(sm) {
    margin-left: 0;
    margin-right: 0;

    > button {
      &.bordered {
        border-left: 1px solid $color-green;
        border-right: 1px solid $color-green;
      }
    }
  }
}