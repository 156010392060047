@use "sass:map";

.page_editor_template {
  width: 100%;

  &.full_width {
    max-width: 100%;
  }

  h2 {
    margin-bottom: 35px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.33;
  }

  @include media-breakpoint-up(md) {
    h2 {
      margin-bottom: 50px;
      font-size: 28px;
    }
  }
}

.editor_type_text_banner {
  .banner-img-wrp {
    display: block;
    line-height: 0;

    img {
      width: 100%;
      min-height: 200px;
      object-fit: cover;
    }
  }

  .banner-text-wrp {
    padding: 30px 20px;
    background: $color-light-grey2;

    .banner-desc {
      *:last-child {
        margin-bottom: 0;
      }

      a {
        color: $color-dark;
        @include link-style();
      }
    }

    h2 {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 1.4;
    }

    p {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 1.43;
    }

    .button-wrp {
      margin-top: 18px;

      .button {
        width: 100%;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    h2 {
      margin-bottom: 24px;
    }
  }
  @include media-breakpoint-up(lg) {
    &.keep-proportions {
      .banner-img-wrp {
        align-self: stretch;

        img {
          min-height: 100%;
        }
      }
    }

    &.banner-left {
      .banner-img-wrp {
        order: 1;
      }

      .banner-text-wrp {
        order: 2;
      }
    }

    .container {
      display: flex;
      align-items: center;
    }

    .banner-img-wrp {
      width: 51.25%;
      order: 2;

      img {
        min-height: 348px;
      }
    }

    .banner-text-wrp {
      display: flex;
      align-items: center;
      align-self: stretch;
      order: 1;
      width: 48.75%;
      padding-left: 50px;
      padding-right: 50px;

      .button-wrp {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .button {
          width: auto;
          margin-right: 8px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .banner-text-wrp {
      padding-left: 100px;
      padding-right: 90px;
    }
  }
}

.editor_type_latest_news {
  .btn-wrp {
    margin-top: 35px;

    .button {
      width: 100%;
    }
  }

  @include media-breakpoint-down(lg) {
    .news-list {
      display: flex;
      gap: 0;
      margin-right: 0;
      overflow: auto;

      .news-item {
        width: 300px;
        flex-shrink: 0;
        margin-right: 16px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .news-list {
      margin-right: -30px;
    }
  }
  @include media-breakpoint-up(md) {
    .btn-wrp {
      margin-top: 56px;
      text-align: center;

      .button {
        width: auto;
      }
    }
  }
}

.editor_type_related_categories {
  padding-top: 35px;
  padding-bottom: 48px;
  background: $color-light-grey4;

  .container {
    padding-right: 0;
  }

  .e-category-list {
    display: flex;
    overflow: auto;

    .item-wrp {
      width: 228px;
      flex-shrink: 0;
      margin-right: 2px;

      .item {
        display: block;
        height: 100%;
        padding: 12px 12px 32px;
        background: $color-white;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: $color-dark;

        .img-wrp {
          margin-bottom: 15px;

          img {
            width: 204px;
            height: 204px;
            object-fit: contain;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 48px;

    .container {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }

    h2 {
      text-align: center;
    }

    .e-category-list {
      justify-content: center;
      margin-left: -2px;
      margin-right: -2px;
      overflow: visible;

      .item-wrp {
        width: 25%;
        padding-left: 2px;
        padding-right: 2px;
        margin-right: 0;

        .item {
          padding: 30px 30px 48px;

          &:hover {
            .img-wrp {
              img {
                transform: scale(1.2);
              }
            }
          }

          .img-wrp {
            margin-bottom: 24px;
            overflow: hidden;

            img {
              width: auto;
              height: auto;
              aspect-ratio: 1/1;
              will-change: transform;
              transform-origin: center center;
              transition: all 0.4s ease-in-out;
            }
          }
        }
      }
    }
  }
}

.editor_type_banner_small_text_box {
  .banner-box {
    display: block;
    position: relative;

    img {
      width: 100%;
      height: 430px;
      object-fit: cover;
    }

    .banner-text-wrp {
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 16px;
      display: flex;

      span {
        display: inline-block;
        padding: 12px;
        background: $color-white;
        line-height: 1.5;
        color: $color-dark;

        &.bold-title {
          font-weight: 500;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .banner-box {
      .banner-text-wrp {
        left: 60px;
        right: 60px;
        bottom: 47px;

        &.align-center {
          justify-content: center;
        }

        &.align-right {
          justify-content: flex-end;
        }

        span {
          max-width: 272px;
          padding: 20px 24px;
        }
      }
    }
  }
}

.editor_type_brands_list {
  margin-bottom: 52px;

  .brands-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    //display: grid;
    //grid-template-columns: repeat(3, 1fr);
    //gap: 20px 40px;

    .item-brand {
      width: 33.33333%;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
      line-height: 0;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        text-align: center;

        &:hover {
          img {
            &.color {
              display: block;
            }

            &.grey {
              display: none;
            }
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;

          &.color {
            display: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 32px;

    .brands-list {
      .item-brand {
        margin-bottom: 40px;

        a {
          img {
            max-width: 100px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .brands-list {
      justify-content: space-between;
      flex-wrap: nowrap;
      margin-left: 0;
      margin-right: 0;

      .item-brand {
        width: auto;
        padding-left: 0;
        padding-right: 0;

        a {
          height: 45px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 56px;
  }
}

.editor_type_categories_list {
  .categories-list {
    display: flex;
    margin-right: -30px;
    overflow: auto;

    .item {
      display: inline-block;
      flex-shrink: 0;
      width: 190px;
      margin-right: 16px;

      &:hover {
        h3 {
          color: $color-green;
        }
      }

      .img-wrp {
        line-height: 0;
        background-color: $color-light-grey4;

        img {
          height: 128px;
          object-fit: cover;
        }
      }

      h3 {
        margin-top: 16px;
        margin-bottom: 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        color: $color-dark;
        transition: color 0.3s ease;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .categories-list {
      margin-right: 0;

      .item {
        width: 230px;

        .img-wrp {
          img {
            height: 150px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .categories-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 35px 25px;
      overflow: visible;

      .item {
        width: 100%;
        margin-right: 0;

        &:hover {
          .img-wrp {
            transform: scale(1.1);
          }
        }

        .img-wrp {
          padding: 25px;
          will-change: transform;
          transform-origin: bottom center;
          transition: all 0.3s ease-in-out;

          img {
            height: auto;
            aspect-ratio: 1.48/1;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .categories-list {
      gap: 48px 40px;

      .item {
        h3 {
          margin-top: 20px;
        }
      }
    }
  }
}

.editor_type_banner_col2 {
  .container {
    padding-right: 0;
  }

  .col2-items {
    display: flex;
    overflow: auto;
  }

  .col2-item {
    width: 300px;
    margin-right: 16px;
    flex-shrink: 0;

    .img {
      display: block;
      line-height: 0;

      img {
        width: 100%;
        aspect-ratio: 1.32/1;
        object-fit: cover;
      }
    }

    h3 {
      margin-top: 24px;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.75;

      a {
        display: block;
        color: $color-dark;
      }
    }

    p {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 1.428;
      color: $color-light-grey3;
    }

    .more-info {
      margin-top: 15px;

      a {
        font-size: 14px;
        color: $color-dark;
        @include link-style();
      }
    }
  }

  @include media-breakpoint-up(md) {
    .container {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }

    .col2-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      overflow: visible;
    }

    .col2-item {
      width: 100%;
      margin-right: 0;
      text-align: center;

      .img {
        img {
          aspect-ratio: 1.62/1;
        }
      }

      h3 {
        margin-top: 32px;
        font-size: 28px;
        line-height: 1.42;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .col2-item {
      &:hover {
        .img {
          img {
            transform: scale(1.25);
          }
        }
      }

      .img {
        overflow: hidden;

        img {
          will-change: transform;
          transform-origin: center center;
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
}

.editor_type_banner_col3 {
  &.simple_columns {
    .list-items {
      display: flex;
      margin-right: -30px;
      overflow: auto;

      .item {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 300px;
        margin-right: 16px;

        .banner-content-wrp {
          height: 100%;
          display: flex;
          flex-direction: column;

          .banner-title {
            margin-bottom: auto;
          }
        }
      }
    }
  }

  .item {
    .img-wrp {
      display: block;
      margin-bottom: 20px;
      line-height: 0;

      .banner-img {
        margin-bottom: 0;
      }
    }

    .banner-img {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      object-fit: cover;
    }

    .banner-content-wrp {
      .banner-title {
        font-weight: 300;
        font-size: 16px;
        line-height: 1.428;
        margin-bottom: 0;

        a {
          position: relative;
          display: inline-block;
          color: $color-dark;

          i {
            margin-left: 8px;
            font-size: 12px;
          }

          &:hover {
            text-decoration-line: underline;
          }
        }
      }

      .button {
        width: 100%;
        margin-top: 20px;
      }

      &.ha-right {
        text-align: right;
      }

      &.ha-center {
        text-align: center;
      }
    }
  }

  .main-btn-wrp {
    margin-top: 30px;
    text-align: center;

    .button {
      width: 100%;
    }
  }

  .tns-nav {
    margin-top: 20px;
    line-height: 0;

    button {
      position: relative;
      width: 11px;
      height: 11px;
      padding: 0;
      border: 0;
      background: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $color-dark-grey;
        transform: translateX(-50%) translateY(-50%);
      }

      &.tns-nav-active {
        &:after {
          background-color: $color-dark;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    &.page_editor_columns_carousel {
      .list-items {
        display: flex;
        margin-right: -30px;
        overflow: auto;

        .item {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          width: 300px;
          margin-right: 16px;

          .banner-content-wrp {
            height: 100%;
            display: flex;
            flex-direction: column;

            .banner-title {
              margin-bottom: auto;
            }
          }
        }
      }
    }

    .main-btn-wrp {
      margin-top: 40px;

      .button {
        width: auto;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.simple_columns, &.page_editor_columns_carousel {
      .list-items {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 30px;
        margin-right: 0;
        overflow: visible;

        .item {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

.editor_type_banner_product {
  .banner-wrp {
    margin-bottom: 30px;
  }

  .img-wrp {
    display: block;
    margin-bottom: 20px;
    line-height: 0;

    .banner-img {
      margin-bottom: 0;
    }
  }

  .banner-img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    object-fit: cover;
  }

  .product-element {
    border: 1px solid $color-light-grey5;
  }

  .banner-content-wrp {
    .banner-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.428;
      margin-bottom: 0;

      a {
        position: relative;
        display: inline-block;
        color: $color-dark;

        i {
          margin-left: 8px;
          font-size: 12px;
        }

        &:hover {
          text-decoration-line: underline;
        }
      }
    }

    .button {
      width: 100%;
      margin-top: 20px;
    }

    &.ha-right {
      text-align: right;
    }

    &.ha-center {
      text-align: center;
    }
  }

  @include media-breakpoint-up(md) {
    .banner-inner-wrp {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;

      &.banner_left {
        .banner-wrp {
          order: 2;
        }

        .product-wrp {
          order: 1;
        }
      }
    }

    .banner-content-wrp {
      .button {
        width: auto;
      }
    }
  }
}

.editor_type_categories_slider {
  .tns-slider {
    display: flex;
  }

  .categories-header {
    margin-bottom: 35px;

    h2 {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 0;
      color: $color-dark;
    }
  }

  .categories-controls {
    display: none;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 48px;
      border: 1px solid $color-dark;
      background: rgba($color-dark, 0);
      font-size: 16px;
      line-height: 1;
      color: $color-dark;

      + button {
        margin-left: -1px;
      }
    }
  }

  .tns-nav {
    margin-top: 20px;
    line-height: 0;

    button {
      position: relative;
      width: 11px;
      height: 11px;
      padding: 0;
      border: 0;
      background: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $color-dark-grey;
        transform: translateX(-50%) translateY(-50%);
      }

      &.tns-nav-active {
        &:after {
          background-color: $color-dark;
        }
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      display: flex;
      flex-direction: column;

      .title:not(.no-hyphens) {
        flex-grow: 1;
        position: relative;
        display: flex;
        margin-top: 8px;
        margin-bottom: 8px;
        align-items: center;
        font-weight: 300;
        font-size: 16px;
        line-height: 1.428;
        font-style: normal;
        text-align: left;
        color: $color-dark;
        hyphens: auto;

        span {
          display: -webkit-box;
          margin-right: 10px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        i {
          font-size: 12px;
        }
      }

      .img-wrp {
        &.fake-img {
          display: block;
          padding-top: calc((100 / 150) * 100%);
          background-color: $color-light-grey;
        }

        img {
          width: 100%;
        }
      }

      &:hover {
        .title {
          span {
            text-decoration-line: underline;
          }
        }
      }
    }

    &.all-products {
      a {
        justify-content: flex-end;
        padding: 16px 16px 20px 16px;
        background-color: $color-light-grey;

        .title {
          position: relative;
          display: flex;
          align-items: center;
          margin-top: 8px;
          margin-bottom: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.428;
          color: $color-dark;

          i {
            font-size: 12px;
          }

          span {
            display: inline-block;
            margin-right: 10px;
          }
        }

        &:hover {
          .title {
            span {
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .tns-nav {
      text-align: center;
    }

    .categories-header {
      margin-bottom: 50px;
    }
  }
  @include media-breakpoint-up(lg) {
    .categories-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 28px;
      }
    }

    .categories-controls {
      display: flex;

      button {
        transition: all 0.3s ease;

        &:hover:not([disabled]) {
          background: $color-dark;
          color: $color-white
        }

        &[disabled] {
          border-color: $color-dark-grey;
          color: $color-dark-grey
        }
      }
    }
  }
}

.editor_type_youtube {
  padding-left: 30px;
  padding-right: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.with_bg {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .video {
    overflow: hidden;
    position: relative;
    padding-top: calc((720 / 1280) * 100%);
    width: 100%;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.editor_type_article_slider {
  .tns-nav {
    margin-top: 20px;
    line-height: 0;

    button {
      position: relative;
      width: 11px;
      height: 11px;
      padding: 0;
      border: 0;
      background: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background-color: $color-dark-grey;
        transform: translateX(-50%) translateY(-50%);
      }

      &.tns-nav-active {
        &:after {
          background-color: $color-dark;
        }
      }
    }
  }

  .banner_column {
    padding-top: 56.25%;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: background .4s;
  }

  .content_column {
    .item {
      font-size: 18px;
      line-height: 1.4;
      color: $color-dark;

      h3 {
        margin-bottom: 15px;
        font-size: 22px;
        font-weight: 400;
        line-height: 1.1;
      }

      .img-carousel {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
        object-fit: cover;
      }

      .tab-inner-wrp {
        font-size: 14px;
        line-height: 1.428;
      }

      &.active {
        background-position: 0 -100%;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .banner_columns-wrp {
      display: grid;
      column-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }

    .content_column {
      .item {
        display: block;
        min-height: 163px;
        padding: 15px 40px 15px 20px;
        background: linear-gradient(to bottom, #fff 50%, $color-light-grey 50%) 0 0/200% 200%;
        transition: background-position 400ms, border-color 400ms, color 200ms;

        &:not(:last-child) {
          border-bottom: 1px solid $color-light-grey;
        }
      }
    }
  }
}

.editor_type_banner {
  .banner-content-wrp {
    margin-top: 20px;

    &.ha-right {
      text-align: right;
    }

    &.ha-center {
      text-align: center;
    }
  }

  .img-wrp {
    display: block;
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .banner-title {
    margin-bottom: 25px;
    color: $color-dark;

    a {
      color: $color-dark;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}

.editor_type_subscribe {
  &.with_bg {
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: cover;
    background-position: center center;
  }

  .grecaptcha-badge {
    bottom: 65px !important;
  }

  .subscribe-form-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;

    .inner-wrp {
      margin-left: auto;
      margin-right: auto;
      max-width: 580px;
      width: 100%;
    }

    .alert-danger {
      padding-top: 14px;
    }

    .title {
      margin-bottom: 20px;
      text-align: center;
      color: $color-white;
    }

    p {
      margin-bottom: 25px;
      font-size: 14px;
      line-height: 1.428;
      text-align: center;
      color: $white;
    }

    form, .fake-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      .messages-wrp {
        width: 100%;
      }

      .form-item {
        width: 100%;
        margin-bottom: 16px;

        .form-label {
          color: $color-white;
        }
      }

      .checkbox {
        width: 100%;
        color: $color-white;

        .check {
          border-color: $color-white;
          color: $color-white;
        }

        p {
          margin-bottom: 0;
          text-align: left;
        }
      }

      .button-wrp {
        width: 100%;
        margin-top: 24px;

        .button {
          width: 100%;
        }
      }
    }

    @include media-breakpoint-up(md) {
      p {
        margin-bottom: 35px;
      }
      form, .fake-form {
        max-width: 400px;

        .button-wrp {
          max-width: 360px;
        }
      }
    }
    @media only screen and (min-width: 1024px) {
      form, .fake-form {
        max-width: 500px;
      }
    }
  }
}