.customers-content {
  margin-top: 24px;

  .back-wrp {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 1.1;

    a {
      color: $color-dark;
      @include link-style();

      i {
        margin-right: 10px;
        font-size: 8px;
      }
    }
  }

  .info-txt {
    font-size: 12px;
  }

  .h-title {
    margin-top: 55px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.25;

    &.mtop0 {
      margin-top: 0;
    }
  }

  .form-wrapper {
    display: grid;
    row-gap: 8px;
  }

  #company {
    margin-top: 16px;
    margin-bottom: 5px;
  }

  .btn-wrp {
    margin-top: 30px;

    .button {
      width: 100%;
    }
  }

  .address-list {
    .address-item {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid rgba($color-dark-grey, 0.4);

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }

      h4 {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.25;
      }

      .html-content {
        margin-bottom: 5px;
      }

      .address-actions {
        .address-action {
          margin-right: 15px;
          font-size: 12px;
          line-height: 1.1;
          color: $color-dark;
          @include link-style();

          &:last-child {
            margin-right: 0;
          }

          i {
            margin-right: 5px;
            font-size: 12px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    flex-grow: 1;
    margin-top: 0;
    margin-left: 60px;

    &.customers-auth {
      .form-wrapper {
        display: block;
      }
    }

    &.full {
      max-width: 100%;
    }

    .h-title {
      font-size: 28px;
    }

    .form-wrapper {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 22px;
    }

    .span2 {
      grid-column: span 2;
    }

    .last-checkbox {
      margin-top: 15px;
    }

    .btn-wrp {
      .button {
        width: auto;
      }
    }

    .address-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 22px;

      .address-item {
        padding: 16px;
        margin-bottom: 0;
        border: 1px solid rgba($color-dark-grey, 0.4) !important;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    max-width: 660px;
    margin-left: 130px;
  }
}