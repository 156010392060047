.bootstrap-select {
  width: 100%;

  &.type-grey {
    .btn {
      padding: 17px 20px;
      border: 0;
      background: $color-dark;

      &.bs-placeholder {
        color: $color-dark !important;
      }
    }

    .dropdown-menu {
      background: $color-dark;
      border: 0;

      li:hover > .dropdown-item {
        background: $color-dark;
      }

      .active {
        > .dropdown-item {
          background: $color-dark;
        }
      }
    }
  }

  .btn {
    padding: 11px 16px;
    border: 1px solid $color-light-grey;
    border-radius: 0;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.1;
    color: $color-dark;

    &:after {
      border-top-color: $color-dark;
    }

    &.dropdown-toggle {
      &:focus {
        outline: none !important;
      }

      &:after {
        margin-left: 0;
        vertical-align: 0;
        border: 0;
        content: "\e909";
        font-size: 5px;
        color: $color-dark;
        @include icomoon();
      }
    }

    &.show {
      border-color: $color-dark;
    }

    &.bs-placeholder {
      color: $color-dark-grey !important;
    }

    &.disabled {
      border-color: $color-light-grey;
      background: $color-light-grey4;
      color: $color-dark-grey;
    }
  }

  .dropdown-menu {
    max-width: 100%;
    padding: 12px 0 16px;
    border-radius: 0;
    border-color: $color-dark;
    box-shadow: none;

    li:hover > .dropdown-item {
      background: none;
      color: $color-dark;
    }

    .active {
      > .dropdown-item {
        background: none;
        font-weight: 600;
      }
    }
  }

  .dropdown-item {
    padding: 12px 16px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1;
    color: $color-dark;
  }
}