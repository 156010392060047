.modal {
  .close-btn {
    top: 25px;
    right: 25px;
  }

  .chargers-modal {
    max-width: 100%;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-body {
    padding: 60px 30px 23px 30px;
  }

  h1 {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.25;
    color: $color-dark;
  }

  .customers-auth {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;

    .modal-body {
      padding-top: 64px;
    }
  }

  @include media-breakpoint-up(sm) {
    .close-btn {
      top: 38px;
    }

    .modal-body {
      padding: 35px 48px 48px;
    }

    h1 {
      font-size: 28px;
    }

    .customers-auth {
      .modal-body {
        padding: 35px 48px 48px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .chargers-modal {
      max-width: 1050px;

      .modal-body {
        padding: 36px 56px;
      }
    }
  }
}

.modal-backdrop {
  background: $color-dark;

  &.show {
    opacity: 0.6;
  }
}