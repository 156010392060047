#comparison-listing {
  margin-top: 25px;

  .page-title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.25;
  }

  .comparison-wrp {
    overflow: auto;
  }

  #comparison-list {
    table-layout: fixed;
    width: 150%;

    .product-img-wrp {
      position: relative;

      .add-to-wishlist {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
    }

    td {
      padding: 24px 20px 24px 0;
      border-bottom: 1px solid $color-light-grey;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.285;
      vertical-align: top;
    }

    .brand {
      padding-top: 0;
      padding-bottom: 10px;
      border-bottom: 0;
      font-size: 12px;
      color: $color-dark-grey;
    }

    .product-name {
      padding-top: 4px;
      border-bottom: 0;

      a {
        font-size: 12px;
        line-height: 2;
        color: $color-dark;
      }
    }

    .remove-cell {
      padding-top: 22px;
      padding-bottom: 38px;
    }

    .product-price {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;

      &.discount {
        color: $color-red;
      }

      .old-price {
        display: block;
        margin-top: 5px;
        font-weight: 300;
        font-size: 12px;
        line-height: 1;
        text-decoration-line: line-through;
        color: $color-dark-grey;
      }
    }

    .remove-comparison {
      font-size: 12px;
      line-height: 1.33;
      color: $color-dark;
      @include link-style();
    }

    .param-title {
      display: block;
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.285;
    }

    .product-img {
      padding-top: 0;
      padding-bottom: 20px;
      border-bottom: 0;

      a {
        display: inline-block;
        line-height: 0;

        img {
          width: 100%;
          height: 140px;
          object-fit: contain;
          aspect-ratio: 1/1;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 60px;
    margin-bottom: 88px;

    .page-title {
      margin-bottom: 58px;
      font-size: 28px;
    }

    #comparison-list {
      width: 100%;
      height: 100%;

      td {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 30px;
      }

      .side-cell {
        padding-right: 42px;
      }

      .sep-image {
        padding-top: 0;
        padding-bottom: 38px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .product-name {
        a {
          font-size: 16px;
          line-height: 1.5;

          &:hover {
            text-decoration-line: underline;
          }
        }
      }

      .remove-cell {
        padding-top: 10px;
        padding-bottom: 38px;
      }

      .product-img {
        padding-bottom: 25px;

        a {
          img {
            height: 270px;
          }
        }
      }
    }
  }
}