#pages-service {
  .page-content {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .service-brands {
    display: grid;
    row-gap: 16px;
    margin-top: 40px;

    .item {
      display: flex;
      flex-wrap: wrap;
      min-height: 134px;
      padding: 24px 28px 36px;
      background: $color-light-grey4;

      img {
        max-width: 120px;
        max-height: 50px;
        margin-right: auto;
      }

      span {
        display: block;
        max-width: 100px;
        margin-left: 15px;
        font-size: 12px;
        line-height: 1.33;
        text-align: right;
        color: $color-dark-grey;
      }

      p {
        width: 100%;
        padding-top: 30px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.428;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .service-brands {
      grid-template-columns: repeat(2, 1fr);
      gap: 22px;
    }
  }
  @include media-breakpoint-up(xl) {
    .page-content {
      max-width: 626px;
      margin-right: 45px;
    }

    .editor_type_text {
      max-width: 84%;
    }

    .service-brands {
      margin-top: 48px;

      .item {
        p {
          padding-top: 25px;
          margin-top: auto;
        }
      }
    }
  }
}