@mixin link-style() {
  text-decoration-line: underline;
  transition: all 0.3s ease;

  @include media-breakpoint-up(lg) {
    &:hover {
      text-decoration-line: none;
    }

    &:focus, &.active {
      text-decoration-line: none;
    }
  }
}

@mixin icomoon() {
  font-family: 'icomoon';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin fontawesome() {
  font-family: "Font Awesome 6 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  --spacing-inline: var(--spacing-6xs);
}