#site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-sticky;

  .main-bar {
    background: $color-white;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 72px;
    }

    .logo {
      img {
        width: 54px;
      }
    }

    .mega-menu-button {
      align-items: center;
      padding: 10px 8px;
      margin-left: 16px;
      color: $color-green;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      transition: all 0.3s ease;

      &:hover, &.active {
        background-color: $color-light-grey4;
      }

      i {
        margin-right: 8px;
        font-size: 18px;
        pointer-events: none;
      }
    }

    .main-bar-sm-menu {
      margin-left: 8px;

      li {
        display: inline-block;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: inline-block;
          color: $color-dark;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          &:hover {
            text-decoration-line: underline;
          }
        }
      }
    }

    .nav-icons {
      display: flex;
      line-height: 0;

      li {
        margin-left: 4px;

        &:first-child {
          margin-right: 0;
        }

        > a,
        .dropdown-center > a,
        .btn-group > a {
          display: inline-flex;
          align-items: center;
          padding: 10px;
          font-size: 20px;
          color: $color-dark;

          &:not(.menu-bars) {
            &:before {
              position: absolute;
              bottom: -2px;
              left: 50%;
              width: 24px;
              height: 2px;
              background: $color-dark;
              transform: translateX(-50%);
              content: '';
              opacity: 0;
            }
          }

          &.show {
            &:before {
              opacity: 1;
            }
          }

          &#wishlist-info {
            .txt {
              display: none;
            }
          }

          &.menu-bars {
            padding-right: 0;
          }

          &#cart-info {
            &:before {
              width: 20px;
            }
          }

          .txt {
            margin-left: 12px;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .main-bar {
      .nav-icons {
        > li {
          margin-left: 6px;

          #cart-info-wrp {
            .products-dropdown {
              right: 10px !important;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .main-bar {
      .nav-icons {
        margin-left: auto;

        > li {
          > a,
          .dropdown-center > a,
          .btn-group > a {
            position: relative;
            font-size: 18px;

            &:hover {
              &:before {
                opacity: 1;
              }
            }

            &#cart-info {
              &:before {
                left: 10px;
                width: calc(100% - 20px);
                transform: none;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    position: sticky;
    left: unset;
    border-bottom: 1px solid $color-light-grey;

    .main-bar {
      .container {
        height: 100px;
        justify-content: flex-start;
      }

      .logo {
        img {
          width: 56px;
        }
      }

      .nav-icons {
        margin-left: 24px;

        > li {
          #cart-info-wrp {
            .products-dropdown {
              right: 0 !important;
            }
          }

          > a,
          .dropdown-center > a,
          .btn-group > a {
            &#cart-info {
              padding-right: 0 !important;

              &:before {
                width: calc(100% - 10px);
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .main-bar {
      .mega-menu-button {
        padding: 10px 16px;
      }

      .main-bar-sm-menu {
        margin-left: 16px;

        li {
          margin-right: 32px;
        }
      }
    }
  }
}