#cart-items {
  .product-small-element {
    align-items: flex-start;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    .img-wrp {
      img {
        width: 79px;
        height: 79px;
      }
    }

    .inner-wrp {
      display: flex;
      flex-direction: column;
      margin-bottom: 4px;
    }

    .inner-details {
      display: flex;
      flex-direction: column;
    }

    .quantity-wrp {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .bootstrap-select .btn {
        padding-top: 8px;
        padding-bottom: 6px;
      }

      .qty {
        font-size: 14px;
        line-height: 1;
      }

      form {
        width: 60px;
      }

      .price {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;

        .old-price {
          font-size: 12px;
        }
      }
    }

    .cart-remove {
      display: inline-block;
      font-weight: 300;
      font-size: 12px;
      line-height: 1.33;
      color: $color-dark-grey;
      @include link-style();
    }

    .details {
      margin-left: 16px;
      margin-right: 0;

      .name {
        margin-top: 8px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 1.428;
        color: $color-dark;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .product-small-element {
      margin-bottom: 28px;

      .inner-wrp {
        flex-direction: row;
        align-items: center;
      }

      .inner-details {
        margin-right: 30px;
      }

      .quantity-wrp {
        form {
          width: 72px;
          margin-right: 30px;
        }

        .bootstrap-select .btn {
          padding-top: 11px;
          padding-bottom: 11px;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .product-small-element {
      .img-wrp {
        img {
          width: 134px;
          height: 134px;
        }
      }

      .quantity-wrp {
        flex-shrink: 0;
        width: 195px;
        margin-left: auto;

        .price {
          font-size: 16px;
        }
      }

      .inner-wrp {
        margin-bottom: 7px;
      }

      .details {
        margin-left: 32px;

        .name {
          font-size: 16px;
        }
      }
    }
  }
}