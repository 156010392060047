#news-list-page {
  padding-top: 25px;

  h1 {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.25;
    text-align: center;
  }

  //.news-filter {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  margin-bottom: 32px;
  //
  //  .dropdown-center {
  //    margin-right: 11px;
  //
  //    + .dropdown-center {
  //      margin-right: 0;
  //    }
  //  }
  //
  //  button {
  //    border: 1px solid $color-dark-grey;
  //    padding: 9px 23px;
  //    background: rgba($color-dark, 0);
  //    font-size: 14px;
  //    line-height: 1.428;
  //    font-weight: 300;
  //    text-align: center;
  //    transition: all 0.3s ease;
  //
  //    &.show {
  //      border-color: $color-dark;
  //      background: $color-dark;
  //      color: $color-white;
  //    }
  //  }
  //
  //  .dropdown-menu {
  //    width: 170px;
  //    padding: 18px;
  //    border-radius: 0;
  //    background: $color-white;
  //    border: 1px solid $color-light-grey;
  //    font-size: 12px;
  //    line-height: 1.428;
  //
  //    li {
  //      margin-bottom: 12px;
  //
  //      &:last-child {
  //        margin-bottom: 0;
  //      }
  //
  //      a {
  //        color: $color-dark;
  //        transition: all 0.3s ease;
  //
  //        &.active {
  //          font-weight: 500;
  //        }
  //      }
  //    }
  //  }
  //}

  .btn-wrp {
    margin-top: 56px;

    .button {
      width: 100%;
    }
  }

  @include media-breakpoint-up(xs) {
    h1 {
      font-size: 32px;
    }

    //.news-filter {
    //  .dropdown-menu {
    //    width: 210px;
    //    padding: 31px;
    //    font-size: 14px;
    //
    //    li {
    //      margin-bottom: 14px;
    //    }
    //  }
    //}
  }
  @include media-breakpoint-up(md) {
    .btn-wrp {
      text-align: center;

      .button {
        width: auto;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    padding-bottom: 50px;

    h1 {
      margin-bottom: 40px;
    }

    //.news-filter {
    //  button {
    //    &:hover {
    //      border-color: $color-dark;
    //      background: $color-dark;
    //      color: $color-white;
    //    }
    //  }
    //
    //  .dropdown-menu {
    //    li {
    //      a {
    //        &:hover {
    //          color: $color-light-green;
    //        }
    //      }
    //    }
    //  }
    //}
  }
  @include media-breakpoint-up(xl) {
    padding-top: 65px;
    padding-bottom: 80px;

    h1 {
      font-size: 28px;
    }

    //.news-filter {
    //  margin-bottom: 40px;
    //}

    .btn-wrp {
      margin-top: 77px;
    }
  }
}