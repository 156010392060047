#orders-listing {
  .orders-list {
    border-top: 1px solid $color-light-grey;

    a {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 8px 15px;
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $color-light-grey;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.428;
      color: $color-dark;

      .order {
        order: 1;
        font-weight: 500;
      }

      .status {
        order: 2;
        text-align: right;
      }

      .date {
        order: 3;
      }

      .price {
        order: 4;
        text-align: right;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .orders-list {
      display: table;
      border-spacing: 0;
      width: 100%;
      border-top: 0;

      .list-header {
        > span {
          display: table-cell;
          padding-top: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid $color-light-grey;
          font-weight: 300;
          font-size: 12px;
          line-height: 1.33;
          color: $color-dark-grey;
        }
      }

      a {
        display: table-row;
        border-bottom: 0;

        > span {
          display: table-cell;
          padding-top: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid $color-light-grey;
        }

        .order {
          font-weight: 300;
        }

        .status {
          text-align: left;
        }

        .price {
          text-align: left;
        }

        .arrow {
          padding-right: 9px;
        }

        i {
          font-size: 18px;
        }
      }
    }
  }
}