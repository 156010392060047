#checkout-layout {
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;

  .checkout-header {
    margin-top: 16px;
    margin-bottom: 28px;
    text-align: center;

    img {
      width: 54px;
    }
  }

  .checkout-footer {
    margin-top: 35px;
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
  }

  h1 {
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    line-height: 1.25;
    color: $color-dark;
  }

  h2 {
    margin-bottom: 24px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25;
    color: $color-dark;

    a {
      font-size: 14px;
      font-weight: 300;
      color: $color-green;
      text-decoration-line: underline;
    }

    .products-total {
      display: inline-block;
      margin-left: 34px;
      font-weight: 400;
      font-size: 16px;
      vertical-align: middle;
    }
  }

  h3 {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    color: $color-dark;
  }

  .form-item {
    margin-bottom: 8px;
  }

  .radio-group {
    .form-item {
      margin-bottom: 16px;
    }
  }

  .delivery-description {
    margin: 8px 0 0;
    font-size: 12px;
    line-height: 1.33;
    color: $color-dark-grey;
  }

  .radio {
    .delivery-description {
      margin-top: 8px;
      margin-bottom: 0;
    }

    .delivery-price {
      font-weight: 500;
    }
  }

  #delivery-type-options {
    margin-bottom: 40px;

    .single-delivery-name {
      display: block;
      font-weight: 300;
      font-size: 12px;
      line-height: 1.33;
      color: $color-dark;

      .delivery-price {
        font-weight: 500;
      }
    }
  }

  .checkbox-sf {
    padding: 20px;
    margin-top: 8px;
    background: $color-light-grey4;

    .checkbox {
      .check {
        background: $color-white;
        border-color: $color-light-grey;
      }
    }
  }

  .checkout-btns-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 32px;

    .button {
      width: 100%;
    }

    .back {
      margin-top: 16px;
      font-size: 12px;
      line-height: 1.5;
      color: $color-dark;

      i {
        margin-right: 10px;
        font-size: 8px;
      }
    }
  }

  #order-information-block {
    > div {
      margin-bottom: 20px;
    }

    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgba($color-dark-grey, 0.4);
    }

    p {
      margin-bottom: 16px;
    }
  }

  #cart-summary {
    margin-bottom: 30px;
  }

  .show-products-wrp {
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba($color-dark-grey, 0.4);

    .show-products {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .show-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.428;
      }

      .show-btn {
        font-size: 14px;
        color: $color-green;
        @include link-style();
      }
    }

    #cart-items {
      padding-top: 22px;
    }
  }

  .tabs-nav {
    .payment-price {
      display: block;
      margin-top: 5px;
      font-size: 12px;
      line-height: 1.25;
      color: $color-dark-grey;
    }
  }

  .form-without-registration {
    margin-top: 25px;

    p {
      margin-bottom: 15px;
      font-weight: 300;
      font-size: 12px;
      line-height: 1.33;
    }

    .registration-check {
      margin-top: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    .row {
      --bs-gutter-x: 22px;

      > * {
        --bs-gutter-x: 22px;
      }
    }

    #cart-confirm {
      .row {
        --bs-gutter-x: 32px;

        > * {
          --bs-gutter-x: 32px;
        }
      }
    }

    .checkout-header {
      margin-top: 40px;
      margin-bottom: 48px;
      text-align: center;

      img {
        width: 78px;
      }
    }

    h1 {
      margin-bottom: 40px;
      font-size: 36px;
      text-align: center;
    }

    h2 {
      font-size: 28px;
    }

    .show-products-wrp {
      padding-bottom: 40px;
      margin-bottom: 35px;
    }

    #step-cart-items {
      display: block;
    }
  }
  @include media-breakpoint-up(lg) {

  }
}