.checkbox {
  position: relative;
  display: block;
  padding-left: 32px;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.33;
  color: $color-dark;
  cursor: pointer;

  &.active {
    .check {
      background: $color-green !important;
      border-color: $color-green !important;

      &:before {
        display: inline;
      }
    }
  }

  input {
    display: none;

    &:checked {
      ~ .check {
        background: $color-green !important;
        border-color: $color-green !important;

        &:before {
          display: inline;
        }
      }
    }
  }

  .check {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid $color-dark;
    font-size: 8px;
    line-height: 0;
    color: $color-white;

    &:before {
      display: none;
    }

    &.grey {
      border-color: $color-light-grey
    }
  }

  a {
    color: $color-dark;
    @include link-style();
  }
}