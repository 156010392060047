@use "sass:map";

#site-footer {
  padding-top: 72px;
  padding-bottom: 30px;

  .logo {
    margin-bottom: 40px;
    line-height: 0;

    img {
      width: 64px;
    }
  }

  h3 {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;

    i {
      margin-left: 10px;
      font-size: 18px;
    }
  }

  .menu {
    font-size: 12px;
    line-height: 1.33;
  }

  .menu-list {
    ul {
      padding-bottom: 28px;

      li {
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: $color-dark;
          text-decoration: none;
        }
      }
    }
  }

  .second-menu-list {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.4;

    li {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $color-dark;
        text-decoration: none;
      }
    }
  }

  .benefits-list {
    margin-top: 45px;

    li {
      position: relative;
      padding-left: 47px;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        position: absolute;
        top: -2px;
        left: 0;
        width: 25px;
        font-size: 19px;
        color: $color-green;
        text-align: center;
      }
    }
  }

  .social-menu {
    margin-top: 55px;
    line-height: 0;

    li {
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 20px;
        color: $color-dark;
      }
    }
  }

  .footer-copyright {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 35px 32px;
    margin-top: 40px;

    .delivery {
      img {
        width: 50px;
      }
    }

    .recycle {
      width: 99px;
    }

    .copyright {
      grid-column: span 2;
      padding-top: 30px;
      border-top: 1px solid $color-light-grey;
      font-size: 12px;
      line-height: 1;
      text-align: center;
    }

    .footer-c-logos {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      a {
        line-height: 0;
      }

      .cards {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .visa {
        width: 50px;
      }

      .mastercard {
        width: 42px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;

      li {
        a {
          font-size: 12px;
          color: $color-dark;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .benefits-list {
      margin-top: 0;
    }

    h3 {
      &:nth-of-type(2n) {
        margin-top: 35px;
      }
    }

    .menu-list {
      ul {
        padding-bottom: 0;
      }
    }

    .logo {
      margin-bottom: 30px;
    }

    .social-menu {
      margin-top: 30px;
    }

    .second-menu-list {
      font-size: 12px;
      line-height: 1.33;
      font-weight: 300;

      li {
        margin-bottom: 18px;
      }
    }

    .footer-col-3 {
      margin-top: 70px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 30px;

    h3 {
      margin-bottom: 35px;
      font-size: 16px;
      cursor: text;

      &:nth-of-type(2n) {
        margin-top: 45px;
      }
    }

    .menu {
      a {
        transition: color 0.3s ease;

        &:hover {
          text-decoration-line: underline;
        }
      }
    }

    .menu-list {
      ul {
        li {
          margin-bottom: 25px;
        }
      }
    }

    .benefits-list {
      li {
        margin-bottom: 25px;
      }
    }

    .second-menu-list {
      li {
        margin-bottom: 14px;
      }
    }

    .footer-copyright {
      display: flex;
      align-items: center;
      gap: 0;
      margin-top: 60px;
      padding-top: 30px;
      border-top: 1px solid $color-light-grey;

      .recycle {
        width: 123px;
      }

      .footer-c-logos {
        order: 3;
        flex-direction: row;
        align-items: center;
        margin-left: auto;

        .cards {
          column-gap: 25px;
          margin-top: 0;
          margin-bottom: 0;
          margin-right: 25px;
        }

        .visa {
          width: 69px;
        }

        .mastercard {
          width: 55px;
        }
      }

      .copyright {
        order: 1;
        padding-top: 0;
        border-top: 0;
        margin-right: 25px;
      }

      ul {
        display: block;
        order: 2;

        li {
          display: inline-block;
          margin-right: 25px;

          &:last-child {
            margin-right: 0;
          }

          a {
            &:hover {
              text-decoration-line: underline;
            }
          }
        }
      }
    }

    .delivery {
      margin-top: 30px;

      img {
        width: 69px;
      }
    }

    .social-menu {
      li {
        margin-right: 25px;

        a {
          font-size: 16px;
          transition: color 0.3s ease;

          &:hover {
            color: $color-light-green;
          }
        }
      }
    }

    .footer-col-1 {
      border-right: 1px solid $color-light-grey;
    }

    .footer-col-2 {
      border-right: 1px solid $color-light-grey;
    }

    .footer-col-3 {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(xl) {
    .social-menu {
      margin-top: 35px;
    }

    .delivery {
      margin-top: 40px;
    }

    .footer-copyright {
      margin-top: 70px;
    }

    .footer-col-1 {
      width: 29%;
    }

    .footer-col-2 {
      width: 42%;
      padding-left: 65px;
    }

    .footer-col-3 {
      width: 29%;
      padding-left: 65px;
    }
  }
}