#side-nav {
  .dropdown {
    button {
      position: relative;

      &.show {
        .fa-minus {
          display: inline-block;
        }

        .fa-plus {
          display: none;
        }
      }

      .fa-minus {
        display: none;
      }

      .dropdown-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-size: 16px;
      }
    }

    .dropdown-menu {
      width: 100%;
      padding: 19px 23px;
      border: 1px solid $color-light-grey;
      border-radius: 0;

      li {
        padding-top: 10px;
        padding-bottom: 10px;

        a {
          display: block;
          font-size: 16px;
          line-height: 1.25;
          font-weight: 300;
          color: $color-dark;

          &.active {
            font-weight: 500;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    width: 270px;
    flex-shrink: 0;

    .dropdown {
      .dropdown-menu {
        position: static;
        display: block;
        padding: 4px 32px;

        li {
          padding-top: 23px;
          padding-bottom: 23px;
          border-bottom: 1px solid $color-light-grey;

          &:last-child {
            border-color: transparent;
          }

          a {
            &:hover {
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }
}