.close-btn {
  position: absolute;
  top: 0;
  right: 25px;
  z-index: 1;
  padding: 5px;
  border: 0;
  background: 0;
  font-size: 13px;
  line-height: 0;
  color: $color-dark;
  transition: all 0.3s ease;

  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      color: $color-green;
    }
  }
}