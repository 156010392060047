#chargers-modal {
  padding-top: 37px;

  .move-info {
    position: absolute;
    top: 0;
    left: 30px;
    z-index: 1;
    padding: 0 27px;

    .hand {
      width: 55px;
      height: auto;
    }

    .arrow {
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 18px;
      height: auto;

      &.right {
        left: unset;
        right: 0;
      }

      &.left {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }

  .table-wrapper {
    width: 100%;
    overflow: auto;
  }

  table {
    min-width: 1050px;
    font-size: 12px;
    line-height: 1.2;
    color: $color-dark;

    &.table-mobile {
      min-width: unset;
    }

    &.table-desktop {
      display: none;
    }

    .text-left {
      text-align: left;
    }

    .header-title-col {
      padding-top: 36px;
      padding-bottom: 15px;
    }

    .header-title {
      display: block;
      padding-right: 30px;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      color: $color-dark;
    }

    td {
      border-bottom: 1px solid $color-light-grey;
      text-align: center;
    }

    .charger-col {
      width: 129px;
      border-left: 1px solid $color-light-grey;
      border-right: 1px solid $color-light-grey;
      padding: 0 10px;
    }

    .accumulator-row {
      td {
        height: 72px;
      }
    }

    .working-time {
      width: 125px;

      span {
        font-weight: 500;
      }
    }

    .col-header-row {
      td {
        border-bottom-color: transparent;
      }

      .charger-col {
        padding-left: 10px;
        padding-right: 10px;
        vertical-align: bottom;
      }
    }

    .product-title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 300px;

      .add-to-cart {
        margin-left: 25px;
      }

      .sold-out{
        min-width: 97px;
        margin-left: 25px;
      }
    }

    .product-title-col {
      width: 314px;
      padding: 0 16px;
      border-left: 1px solid $color-light-grey;
      text-align: left;
    }

    .product-title {
      display: inline-flex;
      align-items: center;

      &.vertical {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 8px;

        img {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }

      &.m-display {
        .name {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      img {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        object-fit: contain;
      }

      picture {
        flex-shrink: 0;
      }

      .name {
        text-transform: uppercase;
        color: $color-dark;

        .working-time-mobile {
          text-transform: none;
          margin-top: 5px;
        }
      }
    }

    .col-title-row {
      td {
        padding-bottom: 5px;
      }
    }

    .col-title {
      color: $color-dark-grey;
    }

    .cart-col {
      width: 167px;
    }

    .bottom-row {
      td {
        height: 68px;
        border-bottom: 0;
      }

      p {
        color: $color-dark-grey;
        margin: 0;

        span {
          font-weight: 500;
        }
      }
    }

    .add-to-cart {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      min-width: 97px;
      height: 32px;
      padding: 0 10px;
      border: 0;
      background-color: $color-green;
      color: $color-white;

      i {
        margin-right: 16px;
        font-size: 12px;
      }

      .price {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    table {
      &.table-mobile {
        display: none;
      }

      &.table-desktop {
        display: table;
      }

      .header-title-col {
        padding-bottom: 25px;
      }

      .product-title-col {
        width: 223px;
      }

      .product-title {
        .name {
          .working-time-mobile {
            display: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .move-info {
      display: none;
    }

    table {
      .add-to-cart {
        transition: all 0.3s ease;

        &:hover {
          background: rgba($color-green, 0.8);
        }
      }

      .product-title {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 0;

    table {
      min-width: unset;

      .header-title {
        padding-right: 75px;
        font-size: 28px;
        line-height: 1.357;
      }
    }
  }
}