#hero-banner {
  position: relative;

  &.has-one-item {
    .hero-nav {
      display: none;
    }

    .hero-slider {
      .content-wrp {
        padding-top: 32px;
      }
    }
  }

  .hero-slider {
    .img-wrp {
      position: relative;
      height: 285px;
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: cover;

      .cover {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        pointer-events: none;

        img {
          width: 136px;
        }
      }
    }

    .content-wrp {
      padding-top: 90px;

      h2 {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 32px;
        line-height: 1.25;
        color: $color-dark;
      }

      p {
        margin-top: 16px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.428;
      }

      .button {
        margin-top: 32px;
      }
    }
  }

  .hero-nav {
    position: absolute;
    left: 0;
    top: 322px;
    z-index: 2;
    width: 100%;
    margin-left: -8px;

    .container {
      display: flex;
      align-items: center;
    }

    .hero-arrow-btn {
      padding: 5px 8px;
      border: 0;
      background: none;
      font-weight: 300;
      font-size: 8px;
      line-height: 1;
      color: $color-dark;
    }

    .hero-nav-counter {
      display: inline-flex;
      align-items: center;
      margin-left: 3px;
      margin-right: 3px;

      .hero-nav-timer {
        position: relative;
        width: 36px;
        height: 1px;
        margin-left: 10px;
        margin-right: 10px;
        background: rgba($color-dark, 0.18);

        &.time-init {
          &:after {
            width: 100%;
            transition: all 9.5s linear;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          background: $color-dark;
        }
      }

      span {
        display: inline-block;
        width: 14px;
        text-align: center;
        font-weight: 300;
        font-size: 12px;
        line-height: 1;
        color: $color-dark;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    box-shadow: 0px 0px 85px rgba(0, 0, 0, 0.08);

    &.has-one-item {
      .hero-slider {
        .content-wrp {
          padding-top: 100px;
        }
      }
    }

    .hero-slider {
      .hero-item {
        position: relative;
      }

      .img-wrp {
        height: 490px;
        background-size: auto 100%;

        .cover {
          width: 38%;
          height: 100%;
          background: $color-white;

          img {
            position: absolute;
            top: 0;
            left: 100%;
            width: auto;
            height: 100%;
          }
        }
      }

      .content-wrp {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        padding-top: 145px;

        .content {
          max-width: 32%;
        }

        h2 {
          font-size: 36px;
          line-height: 1.33;
        }

        .button {
          margin-top: 40px;
        }
      }
    }

    .hero-nav {
      top: 88px;
    }
  }
  @include media-breakpoint-up(xxxl) {
    .hero-slider {
      .img-wrp {
        .cover {
          width: 50%;
        }
      }

      .content-wrp {
        .content {
          max-width: 40%;

          .button {
            margin-top: 50px;
          }
        }
      }
    }
  }
}