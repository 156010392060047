.products-dropdown {
  width: 100vw;
  height: calc(100vh - 72px);
  padding: 23px 24px 34px;
  border-radius: 0;
  border: 1px solid $color-light-grey;
  background: $color-white;
  overflow: auto;

  &.type-add2cart {
    .close-btn {
      top: 21px;
      right: 19px;
      display: inline-block;
    }

    .drop-header {
      &.is-add2cart {
        display: flex;
      }

      &.non-add2cart {
        display: none;
      }
    }

    .list {
      &.is-add2cart {
        display: block;
      }

      &.non-add2cart {
        display: none;
      }
    }
  }

  .close-btn {
    top: 21px;
    right: 19px;
    display: none;
  }

  .drop-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &.is-add2cart {
      display: none;
    }

    .drop-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.25;
      color: $color-dark;
    }

    .total {
      font-weight: 300;
      font-size: 12px;
      line-height: 1;
      color: $color-dark;
    }
  }

  .list {
    margin-bottom: 20px;

    &.is-add2cart {
      display: none;
    }
  }

  .product-small-element {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .button {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  p {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.5;
    color: $color-dark;
  }

  @include media-breakpoint-down(md) {
    top: 13px !important;
  }
  @include media-breakpoint-up(md) {
    width: 370px;
    height: 450px;
  }
}