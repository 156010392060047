.product-actions {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;

  a {
    display: inline-block;
    padding: 6px;
    margin-right: 2px;
    font-size: 14px;
    line-height: 0;
    color: $color-dark;

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    a {
      padding: 8px;
      font-size: 18px;
    }
  }
}