#pages-detailed {
  padding-top: 25px;

  .page-title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.25;
  }

  @include media-breakpoint-up(xs) {
    .page-title {
      font-size: 32px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 72px;
    margin-bottom: 88px;

    .container-header {
      max-width: 830px;
    }

    .page_editor_template {
      .container {
        max-width: 830px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .page-title {
      font-size: 28px;
    }
  }
}