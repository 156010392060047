.alert {
  position: relative;
  width: 100%;
  padding: 16px 20px 16px 44px;
  border: 0;
  border-radius: 0;
  font-size: 12px;
  line-height: 1.66;
  color: $color-white;

  &.alert-warning, &.alert-danger {
    background: $color-red;
  }

  &.alert-success, &.alert-info {
    background: $color-green;
  }

  i {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 12px;
  }
}