@use "sass:map";

#contactUsForm {
  .message-wrp {
    margin-top: 30px;

    textarea {
      padding: 15px 20px;
      min-height: 150px;
    }
  }
}