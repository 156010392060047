.button {
  display: inline-block;
  padding: 20px 24px;
  border: 0;
  background: $color-green;
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  color: $color-white;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: $color-white;
    background: rgba($color-green, 0.8);
  }

  &:disabled, &.disabled {
    background: $color-dark-grey;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.bordered {
    padding: 19px 23px;
    background: rgba($color-green, 0);
    border: 1px solid $color-green;
    color: $color-green;

    &:hover {
      background: rgba($color-green, 0.8);
      border-color: rgba($color-green, 0.8);
      color: $color-white;
    }

    &:focus, .active {
      background: $color-green;
      border-color: $color-green;
      color: $color-white;
    }
  }

  @include media-breakpoint-up(md) {
    &.bordered {
      &.size-44 {
        padding: 13px 20px;
      }
    }
  }
}