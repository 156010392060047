#cart-coupons {
  margin-bottom: 30px;

  .used-cart-coupons {
    margin-bottom: 10px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: inline-block;
        margin-left: 5px;
        padding: 5px;
        font-size: 8px;
        color: $color-dark;
      }
    }
  }

  .input-group-wrp {
    display: flex;

    button {
      padding: 13px 10px;
      font-size: 14px;
    }
  }

  @include media-breakpoint-up(md) {
    .input-group-wrp {
      max-width: 300px;
    }
  }
}