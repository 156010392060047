#checkout-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;

  .step {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    pointer-events: none;
    cursor: default;

    &.done {
      pointer-events: all;
      cursor: pointer;

      .number {
        background: $color-dark-grey;
        color: $color-white;
      }
    }

    &.active {
      .number {
        background: $color-green;
        color: $color-white;
      }
    }

    .number {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background: $color-light-grey;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: $color-dark-grey;
      text-align: center;
    }

    .text {
      margin-left: 10px;
      font-size: 14px;
      line-height: 1;
      font-weight: 300;
      color: $color-dark;
    }
  }

  .line {
    width: 30px;
    height: 1px;
    margin-left: 12px;
    margin-right: 12px;
    background: $color-light-grey3;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 36px;
  }
  @include media-breakpoint-up(lg) {
    .step {
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}