#cart-detailed {
  padding-top: 14px;

  .page-title {
    margin-bottom: 34px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
  }

  .cart-summary-wrp {
    margin-top: 34px;
    margin-left: -30px;
    margin-right: -30px;
    padding: 48px 30px;
    background: $color-light-grey4;
  }

  #cart-vouchers {
    padding-top: 20px;
    margin-top: 24px;
    border-top: 1px solid rgba($color-dark-grey, 0.4);

    .title {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: $color-dark;
    }

    .input-group-wrp {
      display: flex;

      button {
        padding: 13px 10px;
        font-size: 14px;
      }
    }

    .used-vouchers {
      margin-bottom: 10px;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 1.5;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: inline-block;
          margin-left: 5px;
          padding: 5px;
          font-size: 8px;
          color: $color-dark;
        }
      }
    }
  }

  .continue-url {
    position: relative;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 28px;
    font-size: 14px;
    font-weight: 300;

    i {
      position: absolute;
      top: 50%;
      right: 24px;
      z-index: 1;
      transform: translateY(-50%);
      font-size: 7px;
    }
  }

  @include media-breakpoint-up(md) {
    .page-title {
      font-size: 28px;
    }

    .cart-summary-wrp {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 30px;

    .container {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    .page-title {
      width: 100%;
    }

    #cart-items {
      width: calc(100% - 370px);
      padding-right: 60px;
    }

    .cart-summary-wrp {
      width: 370px;
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 64px;
    padding-bottom: 88px;

    .page-title {
      margin-bottom: 64px;
    }

    #cart-items {
      padding-right: 67px;
    }

    .cart-summary-wrp {
      padding-left: 44px;
      padding-right: 44px;
      padding-bottom: 44px;
    }
  }
}