.breadcrumb {
  padding-top: 11px;
  padding-bottom: 11px;
  background: $color-light-grey4;
  font-size: 12px;
  line-height: 1.5;

  a {
    margin-right: 6px;
    color: $color-dark;

    &:last-child {
      margin-right: 0;

      i {
        display: none;
      }
    }

    i {
      margin-left: 10px;
      font-size: 8px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    background: none;

    .container {
      padding-left: 0;
      padding-right: 0;
    }

    a {
      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}