.news-list {
  display: grid;
  row-gap: 56px;

  .news-item {
    .img {
      display: block;
      line-height: 0;

      img {
        aspect-ratio: 1.32/1;
        object-fit: cover;
      }
    }

    h3 {
      margin-top: 28px;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.75;

      a {
        display: block;
        color: $color-dark;
      }
    }

    p {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.428;
      color: $color-light-grey3;
    }

    .more-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      a {
        font-size: 14px;
        color: $color-dark;
        @include link-style();
      }

      time {
        font-size: 12px;
        line-height: 1;
        color: $color-light-grey3;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;

    .news-item {
      display: flex;
      flex-direction: column;

      .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .more-info {
        margin-top: auto;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);

    .news-item {
      &:hover {
        .img {
          img {
            transform: scale(1.25);
          }
        }
      }

      .img {
        overflow: hidden;

        img {
          aspect-ratio: 1.62/1;
          will-change: transform;
          transform-origin: center center;
          transition: all 0.4s ease-in-out;
        }
      }

      .content {
        padding-left: 12px;
        padding-right: 12px;
      }

      h3 {
        a {
          &:hover {
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}