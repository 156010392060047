.radio {
  position: relative;
  display: block;
  padding-left: 32px;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.33;
  color: $color-dark;
  cursor: pointer;

  input {
    display: none;

    &:checked {
      ~ .radio-check {
        border: 0;

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .radio-check {
    position: absolute;
    top: -1px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid $color-light-grey;
    border-radius: 100%;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 4px solid #009C44;
      border-radius: 100%;
      content: '';
      opacity: 0;
    }
  }

  a {
    color: $color-dark;
    @include link-style();
  }
}