#filters {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  padding-top: 23px;
  padding-bottom: 136px;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .container-fluid {
    height: 100%;
    overflow: auto;
  }

  .close-btn {
    top: 26px;
    z-index: 10;
  }

  .title {
    display: block;
    margin-bottom: 28px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.55;
  }

  .filters-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background: $color-white;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.08);

    .container-fluid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 14px;
    }
  }

  #filter-prices {
    h5 {
      margin-bottom: 13px;
    }
  }

  .filter-group {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    h5 {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
    }

    ul {
      &.limit-list-display {
        li {
          &:nth-child(n+6) {
            display: none;
          }
        }
      }

      li {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 1.428;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          font-weight: 500;
        }

        a {
          color: $color-dark;
        }
      }
    }

    .filter-limit-btn-wrp {
      line-height: 0;
    }

    .filter-limit-btn {
      display: inline-block;
      padding: 0;
      border: 0;
      background: none;
      font-size: 14px;
      line-height: 1;
      color: $color-green;

      &.more {
        margin-top: 16px;
      }
    }

    .custom-prices {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      input {
        width: 110px;
        height: 34px;
        padding: 0;
        margin-right: 24px;
        border: 0;
        border-bottom: 1px solid $color-dark;
        background: none;
        font-weight: 300;
        font-size: 14px;
        color: $color-dark;

        &:last-child {
          margin-right: 0;
        }

        &::placeholder {
          color: $color-dark;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    position: static;
    z-index: 1;
    width: 100%;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 1;
    visibility: visible;

    .container-fluid {
      padding-left: 0;
      padding-right: 0;
      overflow: visible;
    }

    .filter-group {
      ul {
        li {
          &.non-check {
            &:hover {
              text-decoration-line: underline;
            }
          }
        }
      }

      .filter-limit-btn {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
}