.product-element {
  position: relative;

  .add-to-cart-listing {
    display: none;
    margin-top: 12px;
    padding: 15px;
    font-size: 14px;
  }

  .product-element-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100%;
    padding: 24px 16px 16px;
  }

  &.sold-out {
    .img-wrp {
      opacity: 0.35;
    }

    .price {
      &.sold-out {
        color: $color-red;
      }
    }
  }

  .product-actions {
    top: 7px;
    right: 5px;
  }

  .tags {
    top: 8px;
    left: 8px;
  }

  .img-wrp {
    display: block;
    margin-bottom: 15px;
    line-height: 0;

    img {
      aspect-ratio: 1/1;
      object-fit: contain;
    }
  }

  .brand {
    display: inline-block;
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.33;
    color: $color-dark-grey;
  }

  .name {
    display: block;
    margin-bottom: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;
    color: $color-dark;
    overflow-wrap: anywhere;
  }

  .price {
    display: block;
    margin-top: auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;

    &.discount {
      display: flex;
      align-items: center;
      color: $color-red;

      .old-price {
        display: inline-block;
        margin-left: 12px;
        font-weight: 300;
        font-size: 12px;
        text-decoration-line: line-through;
        color: $color-dark-grey;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.hover {
      z-index: 2;

      .product-element-inner {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background: $color-white;
        box-shadow: 0 4px 17px rgba(0, 0, 0, 0.13);
        z-index: 1;
      }

      .add-to-cart-listing {
        display: inline-block;
      }
    }

    .product-element-inner {
      padding: 16px 26px 20px;
    }

    .img-wrp {
      margin-bottom: 0;
    }

    .name {
      margin-bottom: 22px;

      &:hover {
        text-decoration-line: underline;
      }
    }

    .brand {
      margin-bottom: 10px;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .product-actions {
      right: 11px;
    }

    .add-to-cart-listing {
      font-size: 16px;
    }

    .brand {
      font-size: 12px;
      font-weight: 300;
    }

    .name {
      font-size: 16px;
      font-weight: 300;
    }

    .price {
      font-size: 16px;

      &.discount {
        .old-price {
          margin-left: 14px;
        }
      }
    }
  }
}