#site-header-top {
  position: relative;
  z-index: $zindex-sticky;
  background: $color-light-grey4;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-top-menu {
    display: flex;
    height: 100%;

    li {
      display: inline-block;
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: block;
        padding-left: 26px;
        padding-right: 26px;
        background: rgba($color-white, 0);
        font-weight: 400;
        font-size: 12px;
        line-height: 40px;
        color: $color-dark;
        transition: all 0.3s ease;

        &.active, &:hover {
          background: $color-white;
        }

        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }

  .header-contact-info {
    a {
      margin-right: 32px;
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      color: $color-dark;
      @include link-style();

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
}