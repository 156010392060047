#products-listing {
  margin-bottom: 40px;

  .breadcrumb {
    margin-left: -30px;
    margin-right: -30px;
  }

  .listing-header {
    margin-top: 24px;
    margin-bottom: 32px;

    h1 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 32px;
      line-height: 1.25;
    }

    .search-query {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 1.25;
    }

    .html-content {
      margin-top: 20px;
      font-size: 12px;
      line-height: 1.66;
    }
  }

  .product-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;
    border-top: 1px solid $color-light-grey5;
  }

  .product-element {
    width: 50%;
    border-right: 1px solid transparent;
    border-bottom: 1px solid $color-light-grey5;
  }

  @include media-breakpoint-down(sm) {
    .product-element {
      &:nth-child(odd) {
        border-right: 1px solid $color-light-grey5;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    .product-list {
      margin-left: 0;
      margin-right: 0;
      border-left: 1px solid $color-light-grey5;

      .product-element {
        width: 33.33333%;
        border-right: 1px solid $color-light-grey5;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .breadcrumb {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: 30px;

    &.full-container-list {
      .main-container {
        grid-template-columns: 1fr;
      }
    }

    .main-container {
      display: grid;
      grid-template-columns: 1.5fr 3.5fr;
      column-gap: 56px;
    }

    .listing-header {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-top: 50px;
    margin-bottom: 80px;

    &.full-container-list {
      .main-container {
        grid-template-columns: 1fr;
      }
    }

    .main-container {
      grid-template-columns: 1fr 4fr;
    }

    .listing-header {
      margin-bottom: 40px;

      h1 {
        font-size: 36px;
        line-height: 1.33;
      }

      .html-content {
        margin-top: 22px;
        line-height: 2;
      }
    }

    .product-list {
      margin-top: 40px;
    }
  }
}