.customers-auth {
  &.non-modal {
    margin-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .auth-title {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.25;
    color: $color-dark;
  }

  .form-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn-wrp {
    margin-top: 32px;
  }

  a {
    color: $color-dark;
    @include link-style();
  }

  p {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.33;
  }

  .other-solution {
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
  }

  @include media-breakpoint-up(sm) {
    max-width: 458px;

    &.non-modal {
      margin-left: auto;
      margin-right: auto;
    }

    .auth-title {
      font-size: 28px;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: 30px;
  }
}