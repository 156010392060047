#cart-confirm {
  margin-top: 28px;

  h1 {
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.55;
  }

  .html-content {
    margin-bottom: 36px;
    font-size: 14px;
    line-height: 2;
  }

  .button {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    margin-top: 72px;
    margin-bottom: 88px;

    .confirm-content {
      max-width: 490px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    h1 {
      margin-bottom: 30px;
      font-size: 28px;
    }

    .html-content {
      margin-bottom: 45px;
    }

    .button {
      width: auto;
    }
  }
}