.product-small-element {
  display: flex;
  align-items: center;

  .img-wrp {
    line-height: 0;
    flex-shrink: 0;

    img {
      width: 65px;
      height: 63px;
      object-fit: contain;
    }
  }

  .details {
    flex-grow: 1;
    margin-left: 15px;

    .brand {
      font-size: 12px;
      line-height: 1;
      color: $color-dark-grey;
    }

    .name {
      margin-top: 5px;
      margin-bottom: 0;
      font-weight: 300;
      font-size: 12px;
      line-height: 1.66;

      a {
        color: $color-dark;
      }
    }
  }

  .price {
    font-size: 12px;
    line-height: 1.66;
    white-space: nowrap;

    &.discount {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-weight: 500;
      color: $color-red;

      .old-price {
        font-weight: 300;
        text-decoration-line: line-through;
        color: $color-dark-grey;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    .details {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @include media-breakpoint-up(lg) {
    .details {
      margin-right: 16px
    }
  }
}