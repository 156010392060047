#quick-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 23px;
  padding-bottom: 23px;
  background-color: $color-white;
  z-index: $zindex-fixed;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .container-fluid {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .close-btn {
    top: 25px;
  }

  .search-title {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.55;
  }

  #quick-search-form {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  .search-input-wrp {
    display: flex;

    button {
      flex-shrink: 0;
      width: 40px;
      padding: 0;
    }
  }

  #search-suggestions-results {
    overflow: auto;

    p {
      margin-bottom: 0;
    }
  }

  .suggestions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;

    a {
      margin-bottom: 10px;
      font-weight: 300;
      font-size: 12px;
      line-height: 1.66;
      color: $color-dark;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .product-small-element {
    padding-right: 8px;
    margin-bottom: 16px;

    .details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .btn-wrp {
    margin-top: 32px;
    text-align: center;

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      color: $color-green;
      transition: all 0.3s ease;
    }
  }

  @include media-breakpoint-up(md) {
    .product-small-element {
      justify-content: center;
    }
  }
  @include media-breakpoint-up(lg) {
    position: relative;
    top: unset;
    left: unset;
    width: 340px;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 45px;
    opacity: 1;
    visibility: visible;

    &.active {
      #search-suggestions-results {
        opacity: 1;
        visibility: visible;
      }
    }

    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }

    #quick-search-form {
      margin-top: 0;
      margin-bottom: 0;
    }

    .product-small-element {
      padding-right: 0;
      margin-bottom: 8px;
    }

    #search-suggestions-results {
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      z-index: $zindex-dropdown;
      width: 100%;
      padding: 24px;
      border: 1px solid $color-light-grey;
      background: $color-white;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
    }

    .btn-wrp {
      margin-top: 28px;

      a {
        &:hover {
          color: rgba($color-green, 0.8);
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    width: 370px;
    margin-left: auto;

    .search-input-wrp {
      .form-control {
        height: 48px;
      }

      button {
        width: 48px;
      }
    }
  }
}