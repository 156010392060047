.add-to-wishlist {
  display: inline-block;
  padding: 6px;
  font-size: 14px;
  line-height: 0;
  color: $color-dark;
  transition: all 0.3s ease;

  &.active {
    i {
      font-weight: 900;
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      color: $color-green;
    }
  }
  @include media-breakpoint-up(xl) {
    padding: 8px;
    font-size: 18px;
  }
}