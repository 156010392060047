#pages-contacts {
  .page-title-wrp {
    margin-bottom: 32px;

    .page-title {
      margin-bottom: 20px;
    }

    .dealers-link {
      font-weight: 300;
      font-size: 16px;
      color: $color-green;
      @include link-style();
    }
  }

  .contacts-block-list {
    margin-bottom: 56px;

    .block-item {
      padding: 40px 32px 48px;
      margin-bottom: 24px;
      background: $color-light-grey4;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.755;
      }

      p {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 1.428;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: $color-dark;
      }
    }
  }

  .managers-list {
    display: grid;
    row-gap: 40px;

    .manager {
      h3 {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.755;
      }

      p {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 1.428;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: $color-dark;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .page-title-wrp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      .page-title {
        margin-bottom: 0;
      }

      .button {
        margin-left: 20px;
      }
    }

    .contacts-block-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;

      .block-item {
        margin-bottom: 0;
      }
    }

    .managers-list {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;
    }
  }
  @include media-breakpoint-up(lg) {
    .page-title-wrp {
      margin-bottom: 36px;
    }

    .contacts-block-list {
      margin-bottom: 64px;

      .block-item {
        padding-left: 40px;
        padding-right: 40px;

        a {
          &:hover {
            text-decoration-line: underline;
          }
        }
      }
    }

    .managers-list {
      a {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
}